import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

@Component({
  selector: 'eole-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent extends SubscribableComponent implements OnInit {
  constructor(private snackbar: MatSnackBar, private router: Router, private i18n: I18n) {
    super();
  }

  public ngOnInit() {
    const snack = this.snackbar.open(
      this.i18n({ id: 'redirect-to-meteole', value: 'Vous allez être redirigé vers les météoles' }),
      '', { duration: 2000 });
    this.subs.push(
      snack.afterDismissed().subscribe(() => {
        this.router.navigate(['meteoles']);
      })
    );
  }
}
