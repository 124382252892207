import { CollectionViewer, DataSource, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { IMeeting } from 'src/interfaces/http/meeting';

import { MeetingService } from './meeting.service';

export class MeetingDataNode {
  /**
   * A node with only the year is expandable
   */
  public get expandable(): boolean {
    return this.item.month === null;
  }
  /**
   * A node with only the year is a top level node
   */
  public get level(): number {
    return this.item.month === null ? 0 : 1;
  }

  /**
   * Meeting of year query subscription (for cancellation purposes)
   */
  public query?: Subscription;

  constructor(
    public item: IMeeting & { participants: [{ attending: boolean }?], meteoles: { eolienId: number, status: string }[] },
    public loading = false,
  ) { }
}

/**
 * Datasource for tree view
 */
export class MeetingDataSource extends DataSource<MeetingDataNode> {
  public get data(): MeetingDataNode[] {
    return this.dataChange.value;
  }
  public set data(value: MeetingDataNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  /**
   * Data changed event
   */
  private dataChange = new BehaviorSubject<MeetingDataNode[]>([]);
  /**
   * Tree control subscription
   */
  private connection: Subscription;

  constructor(
    private treeControl: FlatTreeControl<MeetingDataNode>,
    private meetingService: MeetingService,
  ) {
    super();
  }

  /**
   * Bind the datasource onto the tree
   * @param collectionViewer Tree collection viewer
   */
  public connect(collectionViewer: CollectionViewer): Observable<MeetingDataNode[]> {
    if (this.connection) { return; }

    this.connection = this.treeControl.expansionModel.changed.subscribe(change => {
      if (change.added || change.removed) {
        this.handleTreeControl(change as SelectionChange<MeetingDataNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  /**
   * Unbind the datasource from the tree
   * @param collectionViewer Tree collection viewer
   */
  public disconnect(collectionViewer: CollectionViewer): void {
    if (this.connection) {
      this.connection.unsubscribe();
    }
  }

  /**
   * Handle tree navigation
   * @param change Tree changes
   */
  private handleTreeControl(change: SelectionChange<MeetingDataNode>) {
    change.removed.slice().reverse().forEach(node => this.unloadYear(node));
    change.added.forEach(node => this.loadYear(node));
  }

  /**
   * Loads the selected node's year
   * @param node Selected node
   */
  private loadYear(node: MeetingDataNode) {
    node.loading = true;
    node.query = this.meetingService.getMeetingsOfYear(node.item.year)
      .pipe(
        finalize(() => {
          node.loading = false;
        }),
      )
      .subscribe((meetings) => {
        const nodes = meetings.map(meeting => new MeetingDataNode(meeting));
        const index = this.data.indexOf(node);
        // Append nopes
        this.data.splice(index + 1, 0, ...nodes);
        this.dataChange.next(this.data);
      });
  }

  /**
   * Unloads the selected node's year
   * @param node Selected node
   */
  private unloadYear(node: MeetingDataNode) {
    // Remove loading states
    if (node.query) {
      node.query.unsubscribe();
    } else {
      node.loading = false;
    }

    const nodes = this.data.filter(n => !n.expandable && n.item.year === node.item.year);
    // Remove nodes
    nodes.forEach((n) => {
      this.data.splice(this.data.indexOf(n), 1);
      this.dataChange.next(this.data);
    });
  }
}
