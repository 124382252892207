<div fxFill fxLayout="column">
  <!-- Meteole tree (expandable) -->
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <p *ngIf="loading" i18n="@@side-loading-meteole">Chargement des météoles</p>
  <p *ngIf="!loading && !dataSource.data.length" i18n="@@side-no-meteole">Pas encore de météoles</p>
  <mat-tree class="not-spaced" [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding>
      <button routerLinkActive="mat-primary" fxFlex fxLayoutAlign="start stretch" mat-flat-button
        [routerLink]="['/meteoles', 'dates', node.item.year, node.item.month]">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <ng-container [ngSwitch]="getState(node.item)">
            <fa-icon size="xs" icon="circle" *ngSwitchCase="'complete'" class="green" matTooltip="Complète"
              i18n-matTooltip="@@meteole-status-complete"></fa-icon>
            <fa-icon size="xs" icon="circle" *ngSwitchCase="'dirty'" class="orange" matTooltip="Incomplète"
              i18n-matTooltip="@@meteole-status-dirty"></fa-icon>
            <fa-icon size="xs" icon="circle" *ngSwitchCase="'empty'" class="red" matTooltip="Vide"
              i18n-matTooltip="@@meteole-status-empty"></fa-icon>
            <fa-icon size="xs" icon="circle" *ngSwitchCase="'new'" class="gray" matTooltip="Nouvelle"
              i18n-matTooltip="@@meteole-status-new"></fa-icon>
          </ng-container>
          <div>
            {{node.item.month | monthToDate | date:'MMMM' | uppercase}}
          </div>
        </div>
      </button>
    </mat-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodeToggle fxLayoutGap="10px">
      <button mat-icon-button [attr.aria-label]="'toggle year ' + node.item.year">
        <fa-icon [icon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></fa-icon>
      </button>
      <b>{{node.item.year}}</b>
      <mat-spinner *ngIf="node.loading" mode="indeterminate" diameter="24"></mat-spinner>
    </mat-tree-node>
  </mat-tree>

  <ng-container *ngIf="perm.can('create', 'meeting')">
    <!-- Bottom spacer to ensure that we can scroll content that is below the next fab -->
    <div class="bottom-spacer"></div>

    <button class="bottom-fixed" mat-fab color="primary" [routerLink]="" [queryParams]="{add: true}"
      [preserveFragment]="true" queryParamsHandling="merge">
      <fa-icon icon="plus"></fa-icon>
    </button>
  </ng-container>
</div>
