import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { IUser } from 'src/interfaces/user';

/**
 * Authentication service
 * Manages the user
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends SubscribableComponent {
  /**
   * User token
   */
  public get token(): string {
    return this.privateToken;
  }

  /**
   * Sets User token
   */
  public set token(token: string) {
    this.privateToken = token;
  }

  /**
   * Is the user logged in
   */
  public get authenticated(): boolean {
    return !!this.privateToken;
  }

  /**
   * Live user
   */
  public user: IUser;
  public user$: Subject<IUser> = new Subject<IUser>();

  /**
   * Live token holder
   */
  private privateToken: string;

  // NOTE: This fixes a circular dependency inside the app initializer
  private get router() {
    if (!this.routerCache) {
      this.routerCache = this.injector.get(Router);
    }
    return this.routerCache;
  }
  private routerCache: Router;

  // NOTE: This fixes a circular dependency inside the app initializer
  private get route() {
    if (!this.routeCache) {
      this.routeCache = this.injector.get(ActivatedRoute);
    }
    return this.routeCache;
  }
  private routeCache: ActivatedRoute;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private injector: Injector
    ) {
    super();
  }

  /**
   * Gets the user data from petstore-api once the app is loading
   */
  public authenticate(token: string, user: IUser): void {
    this.user = user;
    this.user$.next(user);
    this.privateToken = token;
  }

  /**
   * Deconnects a user and revokes his access token and refresh token
   */
  public logoffAndRevokeTokens(): void {
    this.subs.push(
      this.oidcSecurityService
        .logoffAndRevokeTokens()
        .subscribe()
    );
  }
}
