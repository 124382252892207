import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'br'
})
export class BrPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) { return value; }
    return value.replace(/\n/g, '<br>');
  }
}
