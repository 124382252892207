import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

import { MeetingService } from 'src/app/services/meeting.service';
import { IMeeting } from 'src/interfaces/http/meeting';

@Component({
  selector: 'eole-dialog-edit-meeting',
  templateUrl: './dialog-edit-meeting.component.html',
  providers: [DatePipe],
})
export class DialogEditMeetingComponent implements OnInit, OnDestroy {
  /**
   * Loading state
   */
  public loading = false;
  /**
   * Saving subscription
   */
  public sending: Subscription;

  /**
   * Form
   */
  public meetingForm: FormGroup;

  /**
   * Last thursday of the month
   */
  public lastThursdayOfMonth: moment.Moment;

  public maxLength = 200;

  /**
   * Get the month and year of the meteole (for display purposes)
   */
  public get date(): string {
    return this.datePipe.transform((this.meetingForm.value.date || moment()).local(), 'MMMM / yyyy').toLocaleUpperCase();
  }

  /**
   * Mobile/Desktop observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public dialogRef: MatDialogRef<DialogEditMeetingComponent>,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe,
    private meetingService: MeetingService,
    @Inject(MAT_DIALOG_DATA) public data?: IMeeting,
  ) { }

  public ngOnInit() {
    const date = moment().set('month', this.data.month - 1).set('year', this.data.year)
      .startOf('month').hour(12).minute(0).second(0).millisecond(0);
    this.lastThursdayOfMonth = this.getLastThursdayOfMonth(date);

    // Generate default form
    this.meetingForm = this.fb.group({
      date: [date, [Validators.required]],
      closure: [this.data.closure, []],
      closed: [this.data.closed, []],
      description: [this.data.description || '', [Validators.maxLength(this.maxLength)]],
      hasMeeting: [this.data.hasMeeting, [Validators.required]],
    });
  }

  public ngOnDestroy() {
    // Cancel any pending save
    if (this.sending) {
      this.sending.unsubscribe();
    }
  }

  /**
   * Filter out weekends
   */
  public weekdaysFilter = (d: moment.Moment): boolean => {
    const day = d.day();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  /**
   * Handle meteole date change (Display and value)
   * @param date Selected date
   * @param datepicker Origin datepicker
   * @param close Wether to close the datepicker or not
   */
  public dateChangedHandler(date: moment.Moment, datepicker?: MatDatepicker<moment.Moment>, close = false) {
    const ctrlValue = this.meetingForm.value.date.local();
    ctrlValue.year(date.local().year());
    ctrlValue.month(date.local().month());
    ctrlValue.startOf('month');
    this.meetingForm.controls.date.setValue(ctrlValue);

    if (this.meetingForm.controls.closure.untouched) {
      this.lastThursdayOfMonth = this.getLastThursdayOfMonth(ctrlValue);
      this.meetingForm.controls.closure.setValue(this.lastThursdayOfMonth);
    }

    if (close && datepicker) {
      datepicker.close();
    }
  }

  /**
   * Close the modal
   * @param meeting If saved, the meeting
   */
  public closeDialog(meeting?: IMeeting) {
    this.dialogRef.close(meeting);
  }

  /**
   * Creates the meeting.
   * If already saving, will cancel the pending request
   */
  public send() {
    // Cancel if already sending
    if (this.loading && this.sending) {
      this.sending.unsubscribe();
      return;
    }

    // Bake the meeting
    const meeting: IMeeting = {
      month: this.meetingForm.value.date.month() + 1,
      year: this.meetingForm.value.date.year(),
      closure: this.meetingForm.value.closure,
      closed: this.meetingForm.value.closed,
      hasMeeting: this.meetingForm.value.hasMeeting,
      description: this.meetingForm.value.description.trim() || null,
    };

    // Save it
    this.loading = true;
    this.sending = this.meetingService.updateMeeting(meeting)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe((savedMeeting) => this.closeDialog(savedMeeting));
  }

  private getLastThursdayOfMonth(reference: moment.Moment) {
    const lastThursdayOfMonth = moment(reference).endOf('month').day('Thursday').hour(12).minute(0).second(0).millisecond(0);
    if (lastThursdayOfMonth.month() !== reference.month()) {
      lastThursdayOfMonth.subtract(1, 'week');
    }
    return lastThursdayOfMonth;
  }
}
