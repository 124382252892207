import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultComponent } from './layouts/default/default.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LostComponent } from './pages/lost/lost.component';
import { MeteoleContentComponent } from './pages/meteoles/meteole-content/meteole-content.component';
import { MeteoleDispatchComponent } from './pages/meteoles/meteole-dispatch/meteole-dispatch.component';
import { MeteoleEolienContentComponent } from './pages/meteoles/meteole-eolien-content/meteole-eolien-content.component';
import { MeteoleKpiComponent } from './pages/meteoles/meteole-kpi/meteole-kpi.component';
import { MeteoleListComponent } from './pages/meteoles/meteole-list/meteole-list.component';
import { MeteoleSessionComponent } from './pages/meteoles/meteole-session/meteole-session.component';

const routes: Routes = [{
  // With default layout
  path: '',
  component: DefaultComponent,
  children: [{
    // Dashboard
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',

  }, {
    path: 'meteoles/kpi',
    component: MeteoleKpiComponent,

  }, {
    path: 'meteoles/eoliens/:eolien',
    component: MeteoleEolienContentComponent,

  }, {
    // Meteole
    path: 'meteoles/:kind',
    component: MeteoleListComponent,

    children: [{
      path: '',
      component: MeteoleDispatchComponent,

    }, {
      path: ':year/:month',
      component: MeteoleContentComponent,

    }],
  }, {
    path: 'meteoles',
    pathMatch: 'full',
    redirectTo: 'meteoles/dates',
  }, {
    // Meteole Play session
    path: 'meteoles/dates/:year/:month/play',
    component: MeteoleSessionComponent,

  }]
}, {
  // Catch all
  path: '**',
  component: LostComponent,
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
