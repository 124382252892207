<div fxLayout="column" fxLayoutAlign="start stretch" class="container">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <button class="floating-save" [disabled]="!hasChanges || (savingMeteoles || savingParticipations)" fxFlexAlign="center start" mat-mini-fab color="warn" (click)="save()">
      <fa-icon *ngIf="!(savingMeteoles || savingParticipations)" size="lg" icon="save"></fa-icon>
      <mat-spinner *ngIf="(savingMeteoles || savingParticipations)" mode="indeterminate" diameter="24"></mat-spinner>
    </button>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-session-label">Session</mat-label>
      <mat-select (selectionChange)="navigateTo($event.value)" [value]="year + '-' + month">
        <mat-option *ngFor="let session of sessions" [value]="session">
          {{session | date:'MMMM y' | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxFlex="grow"></div>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-eolien-label">Collaborateur</mat-label>
      <input matInput (keyup)="applyFilter()" [(ngModel)]="filter.eolien" autocomplete="off">
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-godfather-label">Parrain</mat-label>
      <mat-select [(ngModel)]="filter.godfatherCode" (ngModelChange)="applyFilter()">
        <mat-option>-</mat-option>
        <mat-option *ngFor="let godfather of filteredGodfathers" [value]="godfather.code"
        [matTooltip]="godfather.firstname+' '+godfather.lastname">
          {{godfather.code}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-agencies-label">Agences</mat-label>
      <mat-select multiple [(ngModel)]="filter.agencies" (ngModelChange)="filter.godfatherCode = null; applyFilter()">
        <mat-option *ngFor="let agency of agencies" [value]="agency">
          {{agency}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-domains-label">Domaines</mat-label>
      <mat-select multiple [(ngModel)]="filter.domains" (ngModelChange)="filter.godfatherCode = null; applyFilter()">
        <mat-option *ngFor="let domain of domains" [value]="domain">
          {{domain}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label i18n="@@meteole-session-search-groups-label">Groupes</mat-label>
      <mat-select [(ngModel)]="filter.group" (ngModelChange)="filter.godfatherCode = null; applyFilter()">
        <mat-option *ngFor="let group of groups" [value]="group">
          {{group}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="center">
      <button fxFlex mat-raised-button (click)="exportCsv()">Export CSV</button>
    </div>
  </div>


  <eole-meteole-admin-table [dataSource]="meteolesDataSource"></eole-meteole-admin-table>
  <div *ngIf="loading" fxLayoutAlign="center center">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
  <mat-paginator [pageSizeOptions]="[15, 20, 500]" showFirstLastButtons></mat-paginator>
</div>
