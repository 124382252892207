import { Directive } from '@angular/core';

/**
 * Empty directive for transclusion selection
 */
@Directive({
  selector: '[eoleSide]'
})
export class SideDirective {
  constructor() { }
}
