import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { SidenavService } from 'src/app/services/core/sidenav.service';

/**
 * Layout with a header, a sidebar and a content
 * Use eole-side directive to select the content going in the sidebar
 */
@Component({
  selector: 'eole-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  @ViewChild('drawer', { static: true })
  private drawer: MatSidenav;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sidenav: SidenavService,
  ) { }

  public ngOnInit() {
    this.sidenav.drawer = this.drawer;
  }
  public ngOnDestroy() {
    this.sidenav.drawer = null;
  }
}
