<eole-sidenav>
  <eole-meteole-side eoleSide>
    <form class="container" [formGroup]="filterForm" fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label i18n="@@meteole-kpi-from">Depuis</mat-label>
        <!-- Displayed input -->
        <input matInput [readonly]="true" [value]="dateFrom">
        <!-- Hidden data binding -->
        <input matInput [hidden]="true" [matDatepicker]="picker_from" formControlName="from">
        <mat-datepicker-toggle matSuffix [for]="picker_from"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #picker_from [touchUi]="(isHandset$ | async)" startView="year"
          (yearSelected)="dateChangedHandler($event, 'from')" (monthSelected)="dateChangedHandler($event, 'from', picker_from, true)">
        </mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@meteole-kpi-until">Jusqu'à</mat-label>
        <!-- Displayed input -->
        <input matInput [readonly]="true" [value]="dateTo">
        <!-- Hidden data binding -->
        <input matInput [hidden]="true" [matDatepicker]="picker_to" formControlName="to">
        <mat-datepicker-toggle matSuffix [for]="picker_to"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #picker_to [touchUi]="(isHandset$ | async)" startView="year"
          (yearSelected)="dateChangedHandler($event, 'to')" (monthSelected)="dateChangedHandler($event, 'to', picker_to, true)">
        </mat-datepicker>
      </mat-form-field>
    </form>
  </eole-meteole-side>

  <div fxLayout="column" fxLayoutAlign="center">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

    <ng-container *ngIf="!loading">
      <div fxLayout="row" fxLayout.lt-lg="column">
        <div fxLayout="column" fxFlex="50%" fxLa>
          <div fxFlex>
            <canvas
            baseChart
            [datasets]="summaryChartData"
            [labels]="summaryChartLabels"
            [options]="summaryChartOptions"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'bar'"
            >
            </canvas>
          </div>
          <div fxFlex>
            <canvas
            baseChart
            [datasets]="globalSatisfactionChartData"
            [labels]="globalSatisfactionChartLabels"
            [options]="globalSatisfactionChartOptions"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'line'"
            >
            </canvas>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%">
          <div fxFlex>
            <canvas
            baseChart
            [datasets]="satisfactionChartData"
            [labels]="satisfactionChartLabels"
            [options]="satisfactionChartOptions"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'bar'"
            >
            </canvas>
          </div>
          <div fxFlex>
            <canvas
            baseChart
            [datasets]="indicesChartData"
            [labels]="indicesChartLabels"
            [options]="indicesChartOptions"
            [plugins]="[]"
            [legend]="true"
            [chartType]="'line'"
            >
            </canvas>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</eole-sidenav>
