import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { EolienService } from './services/eolien.service';

import { SubscribableComponent } from './services/core/subscribeable.component';

@Component({
  selector: 'eole-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends SubscribableComponent implements OnInit {
  public newAuthInit: boolean;
  public refreshInit: Promise<boolean>;

  constructor(
    private eolienService: EolienService,
    public oidcSecurityService: OidcSecurityService,
    private auth: AuthenticationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      this.oidcSecurityService
        .checkAuth()
        .subscribe(async (isAuthenticated) => {
          if (isAuthenticated) {
            const authResponse = await this.eolienService.fetchMe().toPromise();
            this.auth.authenticate(authResponse.token, authResponse.user);
            this.newAuthInit = !!this.auth.authenticated;
          } else {
            this.oidcSecurityService.authorize();
          }
        })
    );
  }
}
