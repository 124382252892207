import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlSafe'
})
export class HtmlSafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: string): SafeHtml {
    if (!value) { return value; }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
