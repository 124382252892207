import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as moment from 'moment';

import { environment } from 'src/environments/environment';
import { IKpi } from 'src/interfaces/http/kpi';
import { ConfigService } from './core/config.service';

@Injectable({
  providedIn: 'root'
})
export class KpiService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  public getKpi(from: moment.Moment, to: moment.Moment): Observable<IKpi[]> {
    return this.http.get<IKpi[]>(`${this.configService.config.meteole.url}/kpi/meteole`, {
      params: {
        from: from.format('MM-YYYY'),
        to: to.format('MM-YYYY'),
      }
    });
  }
}
