import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';

import { IUser } from 'src/interfaces/user';

import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

import { PermissionService } from 'src/app/services/core/permission.service';
import { MeetingService } from 'src/app/services/meeting.service';
import { MeteoleService } from 'src/app/services/meteole.service';
import { IMeeting } from 'src/interfaces/http/meeting';
import { IMeteole, IMeteoleMeeting } from 'src/interfaces/http/meteole';

import { DialogEditMeetingComponent } from '../dialog-edit-meeting/dialog-edit-meeting.component';
import { DialogFillMeteoleComponent } from '../dialog-fill-meteole/dialog-fill-meteole.component';
import { DialogParticipantsComponent } from '../dialog-participants/dialog-participants.component';
import { DialogParticipateComponent } from '../dialog-participate/dialog-participate.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'eole-meteole-content',
  templateUrl: './meteole-content.component.html',
})
export class MeteoleContentComponent extends SubscribableComponent implements OnInit {
  /**
   * Current mobile|desktop state
   */
  public isHandset: boolean;
  /**
   * Mobile|desktop state observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  /**
   * Meteole year
   */
  public year: number;
  /**
   * Meteole month
   */
  public month: number;

  /**
   * Fetched meeting
   */
  public meeting: IMeeting;

  public meteoles: IMeteole[] = [];
  public godchildrenMeteoles: IMeteole[] = [];
  public collabMeteoles: IMeteole[] = [];
  public agencyMeteoles: IMeteole[] = [];

  public meteoleItems: MatTableDataSource<IMeteoleMeeting> = new MatTableDataSource([]);
  public godchildrenMeteolesItems: MatTableDataSource<IMeteoleMeeting> = new MatTableDataSource([]);
  public collabMeteolesItems: MatTableDataSource<IMeteoleMeeting> = new MatTableDataSource([]);
  public agencyMeteolesItems: MatTableDataSource<IMeteoleMeeting> = new MatTableDataSource([]);

  public participation: boolean | null = null;
  public participationDebounced = new Subject<boolean>();

  public today: Date = new Date();

  public user: IUser;

  /**
   * Wether we listen for query params or not
   */
  private isListeningForQueryParams = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    public perms: PermissionService,
    private i18n: I18n,
    private meetingService: MeetingService,
    private meteoleService: MeteoleService,
    private auth: AuthenticationService,
  ) {
    super();
  }

  public ngOnInit() {
    this.user = this.auth.user;

    this.subs.push(
      this.auth.user$.subscribe((user) => {
        this.user = user;
      }),
      this.isHandset$.subscribe((isHandset) => this.isHandset = isHandset),
      // Listen for route changes
      this.route.paramMap.subscribe(params => this.onRouteChanged(params)),
      this.participationDebounced.pipe(debounceTime(750), distinctUntilChanged()).subscribe((participation) => {
        this.sendParticipation(participation);
      }),
    );
  }

  public canEdit(meeting: IMeeting, meteole: IMeteole) {
    return moment(meeting.closure).startOf('day').diff(moment()) > 0 && this.perms.can('update', 'meteole', meteole) && !meeting.closed;
  }

  public canChangeParticipation(meeting: IMeeting) {
    return moment(meeting.closure).startOf('day').diff(moment()) > 0 && !meeting.closed && this.user.IS_PARRAIN;
  }

  public minusOneDay(input: Date) {
    return moment(input).subtract(1, 'day').endOf('day');
  }

  public onMeteoleEditClicked(meteole: IMeteole) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: {
        edit: meteole.eolienId,
      },
    });
  }

  public closeMeeting(meeting: IMeeting) {
    // TODO: close meeting dialog: i18n & real design
    const yes = window.confirm('Êtes-vous sûr de vouloir clore cette météole ?');
    if (yes) {
      this.subs.push(this.meetingService.closeMeeting(meeting).subscribe(() => {
        this.meeting.closed = true;
        this.meteoleItems.data = this.meteoles.map(meteole => ({
          meteole,
          editable: this.canEdit(this.meeting, meteole),
          meeting: this.meeting,
        }));
        window.alert('Météole fermée!');
      }));
    }
  }

  private openMeteoleFillDialog(eolienId: number) {
    const meteole = this.meteoles.find(m => m.eolienId === eolienId);
    const dialogRef = this.dialog.open(DialogFillMeteoleComponent, this.isHandset ? {
      data: {
        meteole: JSON.parse(JSON.stringify(meteole)) as IMeteole,
        meeting: JSON.parse(JSON.stringify(this.meeting)) as IMeeting,
      },
      disableClose: true,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
    } : {
        data: {
          meteole: JSON.parse(JSON.stringify(meteole)) as IMeteole,
          meeting: JSON.parse(JSON.stringify(this.meeting)) as IMeeting,
        },
        disableClose: true,
        maxHeight: '100vh',
        width: '600px',
      });

    dialogRef.afterClosed().subscribe((newMeteole: IMeteole) => {
      if (newMeteole) {
        Object.assign(meteole, newMeteole);
      }

      this.router.navigate([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          edit: undefined
        }
      });
    });
  }

  /**
   * Opens the participants list dialog
   */
  private openParticipantsDialog() {
    const dialogRef = this.dialog.open(DialogParticipantsComponent, this.isHandset ? {
      data: this.meeting,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      autoFocus: false,
    } : {
        data: this.meeting,
        width: '600px',
        maxHeight: '100vh',
        autoFocus: false,
      });

    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          participants: undefined
        }
      });
    });
  }

  /**
   * Opens the meeting edit dialog
   */
  private openMeetingEditDialog() {
    const dialogRef = this.dialog.open(DialogEditMeetingComponent, this.isHandset ? {
      data: this.meeting,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      autoFocus: false,
    } : {
        data: this.meeting,
        width: '600px',
        maxHeight: '100vh',
        autoFocus: false,
      });

    dialogRef.afterClosed().subscribe((meeting: IMeeting) => {
      if (meeting) {
        this.meeting = meeting;
        this.meteoleItems.data = this.meteoles.map(meteole => ({
          meteole,
          editable: this.canEdit(this.meeting, meteole),
          meeting: this.meeting,
        }));
      }

      this.router.navigate([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          editMeeting: undefined
        }
      });
    });
  }

  private sendParticipation(value: boolean) {
    this.subs.push(this.meetingService.updateParticipation(this.year, this.month, value).subscribe((result) => {
      if (result.success) {
        this.snackbar.open(
          this.i18n({ id: 'participation-save-success', value: 'Participation enregistrée' }),
          '',
          { duration: 2000 },
        );
      } else {
        this.snackbar.open(
          this.i18n({ id: 'participation-save-failure', value: 'Impossible d\'enregistrer la participation' }),
          '',
          { duration: 2000 },
        );
      }
    }));
  }

  public hasMeteoles(): boolean {
    return this.meteoles && this.meteoles.length > 0;
  }

  public hasPerimeter(): boolean {
    return this.godchildrenMeteoles && this.godchildrenMeteoles.length > 0;
  }

  public hasCollabs(): boolean {
    return this.collabMeteoles && this.collabMeteoles.length > 0;
  }

  public hasAgency(): boolean {
    return this.agencyMeteoles && this.agencyMeteoles.length > 0;
  }

  public hideTabs(): boolean {
    return !this.hasPerimeter() && !this.hasCollabs() && ! this.hasAgency();
  }

  /**
   * Handle route param changes
   * @param params Route params
   */
  private async onRouteChanged(params: ParamMap) {
    this.year = +params.get('year');
    this.month = +params.get('month');

    this.meeting = await this.meetingService.getMeetingByDate(this.year, this.month).toPromise();

    if (this.user.IS_PARRAIN) {
      [
        this.meteoles,
        this.godchildrenMeteoles,
      ] = await Promise.all([
        this.meteoleService.getUserMeteolesByDate(this.year, this.month).toPromise(),
        this.meteoleService.getUnderlingsMeteolesByDate(this.year, this.month).toPromise(),
      ]);

      this.meteoleItems.data = this.meteoles.map(meteole => ({
        meteole,
        editable: this.canEdit(this.meeting, meteole),
        meeting: this.meeting,
      }));

      this.godchildrenMeteolesItems.data = this.godchildrenMeteoles.map(meteole => ({
        meteole,
        editable: false,
        meeting: this.meeting,
      }));
    }

    if (this.user.IS_COMMERCIAL) {
      this.meteoleService.getCollabsMeteolesByDate(this.year, this.month).subscribe(m => {
        this.collabMeteoles = m;
        this.collabMeteolesItems.data = this.collabMeteoles.map(meteole => ({
          meteole,
          editable: false,
          meeting: this.meeting,
        }));
      });
    }

    if (this.user.IS_DIRECTEUR) {
      this.meteoleService.getAgencyMeteolesByDate(this.year, this.month).subscribe(m => {
        this.agencyMeteoles = m;
        this.agencyMeteolesItems.data = this.agencyMeteoles.map(meteole => ({
          meteole,
          editable: false,
          meeting: this.meeting,
        }));
      });
    }

    if (this.meeting.hasMeeting && this.user.IS_PARRAIN) {
      const participant = await this.meetingService.getParticipation(this.year, this.month).toPromise();
      this.participation = participant.attending;
      if (this.participation === null && this.canChangeParticipation(this.meeting)) {
        const participateDialog = this.dialog.open(DialogParticipateComponent, {
          data: this.meeting,
        });
        this.subs.push(participateDialog.afterClosed().subscribe((attending?: boolean) => {
          this.participation = typeof attending === 'boolean' ? attending : null;
        }));
      }
    }
    // Once we get meetings, starts handling query params ONLY ONCE
    if (!this.isListeningForQueryParams) {
      this.isListeningForQueryParams = true;
      this.subs.push(
        this.route.queryParamMap.subscribe(query => this.onQueryChanged(query)),
      );
    }
  }

  /**
   * Handle query param changes
   * @param params Query params
   */
  private async onQueryChanged(params: ParamMap) {
    if (params.get('participants')) {
      this.openParticipantsDialog();
    }
    if (params.get('edit')) {
      this.openMeteoleFillDialog(+params.get('edit'));
    }
    if (params.get('editMeeting')) {
      this.openMeetingEditDialog();
    }
  }
}
