import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { SubscribableComponent } from './subscribeable.component';

import * as CanCan from 'cancan';

import { IMeeting } from 'src/interfaces/http/meeting';
import { IMeteole } from 'src/interfaces/http/meteole';
import { IParticipant } from 'src/interfaces/http/participant';
import { EolienRole, IUser } from 'src/interfaces/user';

export class User {
  constructor(public source?: IUser) { }
}

export class Meeting {
  constructor(public source?: IMeeting) { }
}

export class Meteole {
  constructor(public source?: IMeteole) { }
}

export class Participant {
  constructor(public source?: IParticipant) { }
}

export type Permission = 'create' | 'view' | 'update';
export type Target = 'meeting' | 'meteole' | 'participant' | 'session';

/**
 * Permission service
 * Role and permissions
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionService extends SubscribableComponent {
  private cancan: CanCan;
  private user: IUser;

  constructor(private auth: AuthenticationService) {
    super();
    this.user = this.auth.user;

    this.subs.push(
      this.auth.user$.subscribe((user) => {
        this.user = user;
      }),
    );

    this.cancan = new CanCan();

    this.cancan.allow(User, 'view', Meteole, (user: User, target: Meteole, options) => {
      return user.source && user.source.IS_PARRAIN;
    });

    this.cancan.allow(User, 'update', Meteole, (user: User, target: Meteole, options) => {
      if (!user.source) { return false; }
      if (!target.source) { return false; }
      if (this.isAdmin(user.source)) {
        return true;
      }
      if (!user.source.IS_PARRAIN) { return false; }

      return user.source.ID_EOLIEN === target.source.godfatherId;
    });

    this.cancan.allow(User, 'view', Meeting, (user: User, target: Meeting, options) => {
      return user.source && user.source.IS_PARRAIN;
    });

    this.cancan.allow(User, ['create', 'update'], Meeting, (user: User, target: Meeting, options) => {
      if (!user.source) { return false; }
      return this.isAdmin(user.source);
    });

    this.cancan.allow(User, 'view', Participant, (user: User, target: Participant, options) => {
      if (!user.source) { return false; }
      return this.isAdmin(user.source);
    });
  }

  public can(perm: Permission, target: Target, targetObj?: IMeeting | IMeteole | IParticipant): boolean {
    let realTarget: any;
    if (typeof target === 'string') {
      switch (target) {
        case 'meeting':
          realTarget = new Meeting(targetObj as IMeeting);
          break;
        case 'participant':
          realTarget = new Participant(targetObj as IParticipant);
          break;
        case 'meteole':
          realTarget = new Meteole(targetObj as IMeteole);
          break;
        case 'session':
          if (!this.user) { return false; }
          return this.isAdmin(this.user);
          break;
        default:
          throw new Error('Unknown type');
      }
    } else {
      realTarget = target;
    }
    return this.cancan.can(new User(this.user), perm, realTarget);
  }

  public isAdmin(user: IUser): boolean {
    return user.rolesEolien.includes(EolienRole.admin);
  }

  public isDev(user: IUser): boolean {
    return user.rolesEolien.includes(EolienRole.dev);
  }
}
