import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { zip } from 'rxjs';
import { finalize, mergeAll, mergeMap } from 'rxjs/operators';

import { remove as sanitize } from 'diacritics';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { MeetingService } from 'src/app/services/meeting.service';
import { MeteoleService } from 'src/app/services/meteole.service';

import { IMeteole, IMeteoleSession } from 'src/interfaces/http/meteole';
import { IParticipation } from 'src/interfaces/http/participant';

import * as fileSaver from 'file-saver';
import { IFilterSession } from 'src/interfaces/filter';
import { IFilteredEolien } from 'src/interfaces/filteredGodfather';

@Component({
  selector: 'eole-meteole-session',
  templateUrl: './meteole-session.component.html',
  styleUrls: ['./meteole-session.component.css'],
})
export class MeteoleSessionComponent extends SubscribableComponent implements AfterViewInit, OnDestroy {
  public year: number;
  public month: number;
  public sessions: string[] = [];

  /**
   * States
   */
  public loading = true;
  public savingMeteoles = false;
  public savingParticipations = false;
  public hasChanges = false;

  /**
   * Original meteole map (eolienId: meteole as json string)
   */
  public meteoleMap: { [eolienId: number]: string } = {};
  /**
   * Original participation map (godfatherId: participation as json string)
   */
  public participationMap: { [godfatherId: number]: string } = {};
  public meteolesDataSource: MatTableDataSource<IMeteoleSession> = new MatTableDataSource([]);

  /**
   * Filters values
   */
  public filteredGodfathers: IFilteredEolien[] = [];
  public agencies: string[] = [];
  public domains: string[] = [];
  public groups: string[] = [
    'Tous',
    'Direction',
    'Staff',
    'Referents',
    'Portages',
    'Consultants',
    'Parrains',
  ];

  public groupMap: { [group: string]: string[] } = {
    Direction: [
      'Président',
      'DRH',
      'Directrice Commerciale',
      'Directeur des Opérations',
      'Directeur d\'Agence',
    ],
    Staff: [
      'Ingénieur d\'affaires',
      'Assistante RH',
      'Assistante de Direction',
      'Assistant Ingénieur d\'affaires',
      'Chargé de recrutement',
      'Communication Marketing',
      'Chargée de missions RH',
    ],
    Referents: [
      'Responsable de Domaine',
      'Référent RH',
    ],
    Portages: [
      'Portage salarial',
    ],
    Consultants: [
      'Consultant',
      'Stagiaire',
    ],
    Parrains: [
      'Parrain',
    ],
  };

  public filter: IFilterSession = {
    agencies: [],
    domains: [],
    group: 'Consultants',
  };

  private changeDetectionHandle;
  private changeAutosaveHandle;

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private meteoleService: MeteoleService,
    private meetingService: MeetingService,
  ) {
    super();
  }

  public ngAfterViewInit() {
    this.meteolesDataSource.paginator = this.paginator;
    const pageChange = this.paginator.page.asObservable();
    this.subs.push(
      pageChange.subscribe(() => {
        this.scrollTop();
      }),
      this.meetingService.getMeetingYears().pipe(
        mergeMap(years => years.map(year => this.meetingService.getMeetingsOfYear(year))),
        mergeAll(),
      ).subscribe((sessions) => {
        this.sessions = this.sessions.concat(sessions.reduce((flat, array) => flat.concat(array), []).map(s => `${s.year}-${s.month}`))
          .sort((a, b) => {
            return (new Date(a)).getTime() - (new Date(b)).getTime();
          });
      }),
    );
    this.meteolesDataSource.filterPredicate = this.filterData.bind(this);

    this.subs.push(
      this.route.paramMap.subscribe((params) => this.onRouteChanged(params)),
      this.route.queryParamMap.subscribe((params) => this.onQueryChanged(params)),
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.stopWatches();
  }

  public navigateTo(session: string) {
    const [year, month] = session.split('-');
    this.router.navigate(['meteoles', 'dates', year, month, 'play'], {
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  public applyFilter() {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: {
        a: this.filter.agencies.join('|') || undefined,
        d: this.filter.domains.join('|') || undefined,
        c: this.filter.godfatherCode || undefined,
        e: this.filter.eolien || undefined,
        g: this.filter.group || 'Consultants',
      }
    });
  }

  private filterData(data: IMeteoleSession, filter: string) {
    const f = JSON.parse(filter) as IFilterSession;
    if (f.eolien) {
      const eolien = sanitize(`${data.snapshotEolienFirstname} ${data.snapshotEolienLastname}`).toLowerCase();
      if (eolien.indexOf(sanitize(f.eolien.toLowerCase())) === -1) {
        return false;
      }
    }
    if (f.agencies && f.agencies.length > 0 && !f.agencies.includes(data.snapshotEolienAgency)) {
      return false;
    }
    if (f.domains && f.domains.length > 0 && !f.domains.includes(data.snapshotEolienDomain)) {
      return false;
    }
    if (f.group !== 'Tous' && !this.groupMap[f.group].includes(data.snapshotEolienRole)) {
      return false;
    }

    if (this.filteredGodfathers.every(g => g.code !== data.snapshotGodfatherCode)) {
      this.filteredGodfathers.push({
        code: data.snapshotGodfatherCode,
        firstname: data.snapshotGodfatherFirstname,
        lastname: data.snapshotGodfatherLastname,
        agency: data.snapshotGodfatherAgency,
        domain: data.snapshotGodfatherDomain,
        role: data.snapshotGodfatherRole,
      });
    }

    if (f.godfatherCode && data.snapshotGodfatherCode.toLowerCase() !== f.godfatherCode.toLowerCase()) {
      return false;
    }

    return true;
  }

  public save() {
    const changes = this.detectChanges();
    if (changes.meteoles.length > 0) {
      this.savingMeteoles = true;
      this.subs.push(
        this.meteoleService.updateMeteoles(changes.meteoles)
          .pipe(finalize(() => this.savingMeteoles = false))
          .subscribe((newMeteoles) => {
            newMeteoles.forEach((newMeteole) => {
              const index = this.meteolesDataSource.data.findIndex(m => m.eolienId === newMeteole.eolienId);
              this.meteoleMap[newMeteole.eolienId] = JSON.stringify(newMeteole);
              Object.assign(this.meteolesDataSource.data[index], newMeteole);
            });
          }),
      );
    }
    if (changes.participations.length > 0) {
      this.savingParticipations = true;
      zip(...changes.participations.map(p =>
        this.meetingService.updateParticipation(this.year, this.month, p.attending, p.godfatherId)
      )).pipe(finalize(() => this.savingParticipations = false))
        .subscribe(successes => {
          changes.participations.forEach(p => this.participationMap[p.godfatherId] = JSON.stringify(p));
        });
    }
  }

  private scrollTop() {
    const scrollToTop = setInterval(() => {
      const scrollElement = document.getElementsByClassName('scroll-container')[0];
      if (!scrollElement) {
        clearInterval(scrollToTop);
        return;
      }
      const pos = scrollElement.scrollTop;
      if (pos > 0) {
        scrollElement.scrollTo(0, pos - (pos / 5));
      } else {
        clearInterval(scrollToTop);
      }
    }, 16);
  }

  private detectChanges(): ({ meteoles: IMeteole[], participations: IParticipation[] }) {
    const changes: { meteoles: IMeteole[], participations: IParticipation[] } = { meteoles: [], participations: [] };
    const participations: { [godfatherId: string]: IParticipation } = {};
    this.meteolesDataSource.data.forEach(({ godfatherParticipation, ...m }) => {
      if (JSON.stringify(m) !== this.meteoleMap[m.eolienId]) {
        changes.meteoles.push(m);
      }
      // Participation changes, ignoring godfather not giving attendence
      // so that it ignores generated attendence for godfather not existing anymore
      if (JSON.stringify(godfatherParticipation) !== this.participationMap[m.godfatherId] && godfatherParticipation.attending !== null) {
        participations[m.godfatherId] = godfatherParticipation;
      }
    });
    changes.participations = Object.values(participations);
    this.hasChanges = changes.meteoles.length + changes.participations.length > 0;
    return changes;
  }

  private onQueryChanged(params: ParamMap) {
    this.filter = {
      agencies: params.get('a') ? params.get('a').split('|') : [],
      domains: params.get('d') ? params.get('d').split('|') : [],
      group: params.get('g') || 'Consultants',
      godfatherCode: params.get('c'),
      eolien: params.get('e'),
    };

    this.filteredGodfathers = [];
    this.meteolesDataSource.filter = JSON.stringify(this.filter);
  }

  private onRouteChanged(params: ParamMap) {
    this.stopWatches();
    this.year = +params.get('year');
    this.month = +params.get('month');

    zip(
      this.meetingService.getParticipants(this.year, this.month),
      this.meteoleService.getAllMeteolesByDate(this.year, this.month),
    )
      .pipe(finalize(() => this.loading = false))
      .subscribe(([participants, meteoles]) => {
        this.agencies = [];
        this.domains = [];

        meteoles.forEach(m => {
          if (m.snapshotEolienAgency && !this.agencies.find(agency => agency === m.snapshotEolienAgency)) {
            this.agencies.push(m.snapshotEolienAgency);
          }
          if (m.snapshotEolienDomain && !this.domains.find(domain => domain === m.snapshotEolienDomain)) {
            this.domains.push(m.snapshotEolienDomain);
          }
        });

        const validMeteoles = meteoles.filter(m => m.snapshotGodfatherCode);

        this.meteoleMap = {};
        this.participationMap = {};
        validMeteoles.forEach(m => this.meteoleMap[m.eolienId] = JSON.stringify(m));
        participants.forEach(p => this.participationMap[p.godfatherId] = JSON.stringify(p));

        this.meteolesDataSource.data = validMeteoles.map(m => ({
          ...m,
          // Add attendence and fake attendence for unknown godfathers
          godfatherParticipation: participants.find(p => p.godfatherId === m.godfatherId)
            || { godfatherId: m.godfatherId, attending: null } as IParticipation,
        }));
        this.meteolesDataSource.filter = JSON.stringify(this.filter);

        this.changeDetectionHandle = setInterval(() => this.detectChanges(), 2000);
        this.changeAutosaveHandle = setInterval(() => this.save(), 30000);
      });
  }

  /**
   * Formats the data and downloads the csv
   */
  public exportCsv() {
    const data = this.meteolesDataSource.filteredData.map(d => {
      return {
        godfather: d.snapshotGodfatherFirstname + ' ' + d.snapshotGodfatherLastname,
        eolien: d.snapshotEolienFirstname + ' ' + d.snapshotEolienLastname,
        mis: this.satisfactionToX(d.satisfactionMission),
        acc: this.satisfactionToX(d.satisfactionAccompanying),
        rec: this.satisfactionToX(d.satisfactionAcknowledgement),
        app: this.satisfactionToX(d.satisfactionBelonging),
        car: this.satisfactionToX(d.satisfactionCareer),
        summary: this.summaryToColor(d.summary),
        remarks: d.remarks,
        actionsGodfather: d.actionsGodfather,
        actionsMeeting: d.actionsMeeting,
      };
    });

    const header = [
      'Parrain', 'Collaborateur',
      'Mission', 'Accompagnement RH', 'Reconnaissance', 'Appartenance', 'Carrière',
      'Synthèse', 'Remarques', 'Action parrain', 'Décision séance',
    ];

    const nullRreplacer = (key, value) => value === null ? '' : value;
    const keys = Object.keys(data[0]);

    const csvArray = data.map(row => keys.map(fieldName => JSON.stringify(row[fieldName], nullRreplacer)).join(','));
    csvArray.unshift(header.join(','));

    const csv = '\uFEFF' + csvArray.join('\r\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });

    fileSaver.saveAs(blob, `meteole_${this.month}_${this.year}.csv`);
  }

  private summaryToColor(sym: number) {
    let color: string;

    switch (sym) {
      case 0:
        color = 'Noir';
        break;
      case 1:
        color = 'Rouge';
        break;
      case 2:
        color = 'Orange';
        break;
      case 3:
        color = 'Vert';
        break;

      default:
        color = '';
        break;
    }

    return color;
  }

  private satisfactionToX(sat: boolean) {
    return sat ? 'X' : '';
  }

  private stopWatches() {
    if (this.changeDetectionHandle) {
      clearInterval(this.changeDetectionHandle);
      this.changeDetectionHandle = null;
    }
    if (this.changeAutosaveHandle) {
      clearInterval(this.changeAutosaveHandle);
      this.changeAutosaveHandle = null;
    }
  }
}
