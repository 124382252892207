import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { version } from 'package.json';

import { DialogChangelogComponent } from 'src/app/pages/dialog-changelog/dialog-changelog.component';
import { DialogImpersonateComponent } from 'src/app/pages/dialog-impersonate/dialog-impersonate.component';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { PermissionService } from 'src/app/services/core/permission.service';
import { SidenavService } from 'src/app/services/core/sidenav.service';
import { StoreService } from 'src/app/services/core/store.service';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

import { IUser } from 'src/interfaces/user';

@Component({
  selector: 'eole-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends SubscribableComponent implements OnInit {
  /**
   * Current mobile|desktop state
   */
  public isHandset: boolean;
  /**
   * Mobile|desktop state observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  public user: IUser;
  public defaultImage = '/assets/user-64x64.png';

  public version = version;
  public notification: string | number;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: StoreService,
    private auth: AuthenticationService,
    public sidenav: SidenavService,
    private perms: PermissionService,
  ) {
    super();
  }

  public async ngOnInit() {
    this.user = this.auth.user;

    this.subs.push(
      this.auth.user$.subscribe((user) => {
        this.user = user;
        console.log(this.user);
      }),
      this.isHandset$.subscribe((isHandset) => this.isHandset = isHandset),
      // Listen for route changes
      this.route.queryParamMap.subscribe(query => this.onQueryChanged(query)),
    );

    const storedVersion = await this.store.meta.getItem<string>('version');
    if (storedVersion !== this.version) {
      this.notification = 1;
      await this.store.meta.setItem<string>('version', this.version);
    }
  }

  /**
   * Handle query param changes
   * @param params Query params
   */
  private async onQueryChanged(params: ParamMap) {
    if (params.get('impersonate')) {
      this.openImpersonateDialog();
    }
    if (params.get('changelog')) {
      this.openChangelogDialog();
    }
  }

  /**
   * Opens the impersonate dialog
   */
  private openImpersonateDialog() {
    const dialogRef = this.dialog.open(DialogImpersonateComponent, this.isHandset ? {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      autoFocus: false,
    } : {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(async (ok) => {
      const urlTree = this.router.createUrlTree([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          impersonate: undefined
        },
      });
      await this.router.navigateByUrl(urlTree);
    });
  }

  /**
   * Opens the changelog dialog
   */
  private openChangelogDialog() {
    if (this.notification) {
      this.notification = '';
    }

    const dialogRef = this.dialog.open(DialogChangelogComponent, this.isHandset ? {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      autoFocus: false,
    } : {
      width: '600px',
      maxHeight: '100vh',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(() => {
      const urlTree = this.router.createUrlTree([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          changelog: undefined
        },
      });
      return this.router.navigateByUrl(urlTree);
    });
  }

  public canImpersonate(user: IUser): boolean {
    return this.perms.isDev(user);
  }

  public async logout() {
    this.auth.logoffAndRevokeTokens();
  }
}
