import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { EolienService } from '../eolien.service';
import { AuthenticationService } from './authentication.service';
import { ConfigService } from './config.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  public gettingRefreshToken: boolean;

  public initialUnauthenticatedLength: number;

  public unauthenticatedRoutesPrefix: string[] = [
    `${environment.api}/config`,
    `${environment.api}/auth/callback`,
  ];

  public RoutesPrefix: string[] = [];

  public authenticatedRoutesPrefix: string[] = [
    environment.api,
    environment.api.replace(/\/$/g, ''),
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private eolienService: EolienService,
    private oidcSecurityService: OidcSecurityService,
  ) { }

  /**
   * Intercept outbound requests
   *
   * * Adds authentication headers if needed
   *
   * @param req The intercepted request
   * @param next The http handler
   * @return response handling
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<undefined> {
    if (this.unauthenticatedRoutesPrefix.length === this.initialUnauthenticatedLength && this.configService.loaded) {
      this.RoutesPrefix.push(this.configService.config.keycloak.url);
    }

    // Remove scheme://domain and leading /
    const path = req.url.replace(/^(https?:\/\/)(.*?)\//, '');

    if (this.RoutesPrefix.some((route) => req.url.startsWith(route)) ||
      this.unauthenticatedRoutesPrefix.some((route) => path.startsWith(route)) ||
      !this.authenticatedRoutesPrefix.some((route) => path.startsWith(route))) {
      return next.handle(req);
    }

    return next.handle(this.addPolicies(req))
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 && !this.gettingRefreshToken) {
            this.gettingRefreshToken = true;

            return this.oidcSecurityService.forceRefreshSession()
              .pipe(
                filter(result => result !== null),
                take(1),
                switchMap((res) => {
                  // Gets a new jwt from the back using the newly issued keycloak jwt
                  if (!!this.authenticationService.authenticated) { this.authenticationService.token = res.accessToken; }
                  return this.eolienService.fetchMe()
                    .pipe(
                      switchMap((authResponse) => {
                        // Sets the new jwt issued by the backend
                        this.authenticationService.token = authResponse.token;

                        this.gettingRefreshToken = false;
                        return next.handle(this.addPolicies(req)).toPromise();
                      })
                    );
                }),
                catchError(() => {
                  this.authenticationService.logoffAndRevokeTokens();
                  return of(undefined);
                })
              );
          } else {
            return throwError(err);
          }
        })
      );
  }

  /**
   * Increments a request with required bits for authentication
   * @param req The request to enhance
   */
  public addPolicies(req: HttpRequest<any>): HttpRequest<any> {
    const token = !!this.authenticationService.authenticated ? this.authenticationService.token : this.oidcSecurityService.getToken();

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }
}
