<ng-container *ngIf="loading">
  <h2 i18n="@@meteole-dispatch-loading">Chargement des informations</h2>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>

<ng-container *ngIf="!loading">
  <eole-center>
    <div fxLayout="column" fxLayoutGap="10px" fxFlexAlign="center center">
      <h2 i18n="@@meteole-dispatch-nothing">Il n'y a rien à voir pour l'instant</h2>
      <button mat-raised-button (click)="retry()" i18n="@@meteole-dispatch-retry">Réessayer ?</button>
    </div>
  </eole-center>
</ng-container>
