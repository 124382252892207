<mat-progress-bar mode="indeterminate" *ngIf="!meeting"></mat-progress-bar>
<div *ngIf="meeting" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <h2 class="uppercase">{{ meeting.month | monthToDate | date:'MMMM' }} {{ meeting.year }}</h2>
    <!-- TODO: remove or true and add read only mode session -->
    <button mat-icon-button *ngIf="perms.can('create', 'session') && (meeting.month === today.getMonth() + 1 || true)"
      [routerLink]="'play'">
      <fa-icon class="green" matTooltip="Démarrer" i18n-matTooltip="@@meteole-start" matTooltipPosition="after"
        icon="play"></fa-icon>
    </button>
    <button mat-icon-button *ngIf="perms.can('create', 'session') && (meeting.month === today.getMonth() + 1 || true)"
      [routerLink]="" [queryParams]="{editMeeting: true}" [preserveFragment]="true" queryParamsHandling="merge">
      <fa-icon matTooltip="Configurer" i18n-matTooltip="@@meteole-configure" matTooltipPosition="after" icon="cog">
      </fa-icon>
    </button>
    <button mat-icon-button *ngIf="perms.can('create', 'session') && !meeting.closed && (meeting.month === today.getMonth() + 1 || true)"
      (click)="closeMeeting(meeting)">
      <fa-icon matTooltip="Clore" i18n-matTooltip="@@meteole-close" matTooltipPosition="after" icon="unlock">
      </fa-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-checkbox *ngIf="meeting.hasMeeting" labelPosition="before" class="uppercase primary" color="primary" i18n="@@meteole-header-participation"
      [indeterminate]="participation === null" [(ngModel)]="participation" [disabled]="!canChangeParticipation(meeting)"
      (change)="participationDebounced.next($event.checked)">participation</mat-checkbox>
    <button *ngIf="perms.can('view', 'participant')" mat-icon-button [routerLink]=""
      [queryParams]="{participants: true}" [preserveFragment]="true" queryParamsHandling="merge">
      <fa-icon icon="tasks" size="lg"></fa-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="40px">
    <div>
      <span class="uppercase primary" i18n="@@meteole-header-closure-date">date cloture</span>
      {{ minusOneDay(meeting.closure) | date:'shortDate' }}
      <span class="uppercase darkgray" i18n="@@meteole-header-closure-date-hint">(minuit)</span>
    </div>
    <div *ngIf="meeting.description">
      <span class="uppercase primary" i18n="@@meteole-header-description">description</span>
      {{ meeting.description }}
    </div>
  </div>

  <mat-tab-group *ngIf="!hideTabs()">
    <mat-tab label="MES FILLEULS" *ngIf="hasMeteoles()">
      <div class="auto-height">
        <eole-meteole-table [tableType]="'meteoles'" [items]="meteoleItems" (editClick)="onMeteoleEditClicked($event)"></eole-meteole-table>
      </div>
    </mat-tab>

    <mat-tab label="MON PÉRIMÈTRE" *ngIf="hasPerimeter()">
      <div class="auto-height">
        <eole-meteole-table [tableType]="'perimeter'" [items]="godchildrenMeteolesItems"></eole-meteole-table>
      </div>
    </mat-tab>

    <mat-tab label="MES COLLABS" *ngIf="hasCollabs()">
      <div class="auto-height">
        <eole-meteole-table [tableType]="'collabs'" [items]="collabMeteolesItems" [useFilters]="true"></eole-meteole-table>
      </div>
    </mat-tab>

    <mat-tab label="MON AGENCE" *ngIf="hasAgency()">
      <div class="auto-height">
        <eole-meteole-table [tableType]="'agency'" [items]="agencyMeteolesItems" [useFilters]="true"></eole-meteole-table>
      </div>
    </mat-tab>
  </mat-tab-group>

  <eole-meteole-table *ngIf="hideTabs()" [tableType]="'meteoles'" [items]="meteoleItems" (editClick)="onMeteoleEditClicked($event)"></eole-meteole-table>

</div>
