import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IMeteole } from 'src/interfaces/http/meteole';

/**
 * Event service
 * Application wide event service
 */
@Injectable({
  providedIn: 'root'
})
export class EventService {
  public meteoleSaved = new Subject<IMeteole>();
}
