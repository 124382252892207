import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eole-lost',
  templateUrl: './lost.component.html',
})
export class LostComponent implements OnInit {

  constructor() { }

  public ngOnInit() { }
}
