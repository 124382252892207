import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eole-dialog-changelog',
  templateUrl: './dialog-changelog.component.html',
})
export class DialogChangelogComponent {
  constructor(public dialogRef: MatDialogRef<DialogChangelogComponent>) {}
}
