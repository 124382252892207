import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

import { DialogAddMeetingComponent } from 'src/app/pages/meteoles/dialog-add-meeting/dialog-add-meeting.component';
import { EventService } from 'src/app/services/core/event.service';
import { PermissionService } from 'src/app/services/core/permission.service';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { MeetingDataNode, MeetingDataSource } from 'src/app/services/meeting.datasource';
import { MeetingService } from 'src/app/services/meeting.service';
import { IMeeting } from 'src/interfaces/http/meeting';

@Component({
  selector: 'eole-meteole-side-dates',
  templateUrl: './meteole-side-dates.component.html',
  styleUrls: ['./meteole-side-dates.component.css'],
})
export class MeteoleSideDatesComponent extends SubscribableComponent implements OnInit {
  /**
   * Top level tree loading
   */
  public loading = true;

  /**
   * Tree control
   */
  public treeControl = new FlatTreeControl<MeetingDataNode>(node => node.level, node => node.expandable);
  /**
   * Meteoles datasource
   */
  public dataSource = new MeetingDataSource(this.treeControl, this.meetingService);

  /**
   * Current mobile|desktop state
   */
  public isHandset: boolean;
  /**
   * Mobile|desktop state observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private eventService: EventService,
    private meetingService: MeetingService,
    private dialog: MatDialog,
    public perm: PermissionService,
  ) {
    super();
  }

  public ngOnInit() {
    this.subs.push(
      // Listen for responsiveness changes
      this.isHandset$.subscribe((isHandset) => this.isHandset = isHandset),
      // Listen for query param state changes
      this.route.queryParamMap.subscribe(params => this.onQueryChanged(params)),
      // Listen for events
      this.eventService.meteoleSaved.pipe(shareReplay()).subscribe((newMeteole) => {
        const node = this.dataSource.data.find(n => n.item.id === newMeteole.meetingId);
        if (!node || !node.item) { return; }
        const meteole = node.item.meteoles.find(m => m.eolienId === newMeteole.eolienId);
        if (!meteole) { return; }
        meteole.status = newMeteole.status;
      }),

      // Initial top level fetch
      this.meetingService.getMeetingYears()
        .pipe(finalize(() => this.loading = false))
        .subscribe((years) => {
          // Set top level data in the source
          this.dataSource.data = years.map(year => new MeetingDataNode({
            year,
            month: null,
            hasMeeting: null,
            closure: null,
            closed: null,
            participants: [],
            meteoles: []
          }));
          // Lookup for an existing expanded node (route) or expand the current year
          if (this.route.children && this.route.children.length > 0) {
            this.route.children.forEach(route => this.subs.push(route.paramMap.subscribe(params => this.onRouteChanged(params))));
          } else {
            this.expand(new Date().getFullYear());
          }
        }),
    );
  }

  /**
   * Opens the dialog
   */
  private openDialog() {
    const dialogRef = this.dialog.open(DialogAddMeetingComponent, this.isHandset ? {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
    } : {
        width: '400px',
        maxHeight: '100vh',
      });

    dialogRef.afterClosed().subscribe((result?: IMeeting) => {
      if (result) {
        this.onMeetingCreated(result);
      }
      this.router.navigate([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          add: undefined
        }
      });
    });
  }

  /**
   * Wether the node is expandable or not
   */
  public hasChild = (_: number, node: MeetingDataNode) => node.expandable;

  public getState(meeting: IMeeting & { participants: [{ attending: boolean }?], meteoles: { id: number, status: string }[] }) {
    if (meeting.meteoles.every(m => m.status === 'filled')) {
      return 'complete';
    }
    if (moment(meeting.closure).diff(moment()) < 0) {
      return meeting.meteoles.some(m => m.status === 'filled') ? 'dirty' : 'empty';
    }

    return 'new';
  }

  /**
   * Called when a meeting is created
   *
   * Refresh list an navigates to the newly created meeting
   * @param meeting New meeting
   */
  private onMeetingCreated(meeting: IMeeting) {
    this.loading = true;
    this.subs.push(
      // Refresh top level list
      this.meetingService.getMeetingYears()
        .pipe(finalize(() => this.loading = false))
        .subscribe((years) => {
          // Overwrite data source
          this.dataSource.data = years.map(year => new MeetingDataNode({
            year,
            month: null,
            hasMeeting: null,
            closure: null,
            closed: null,
            participants: [],
            meteoles: [],
          }));
          // Navigates to it
          this.router.navigate(['meteoles', 'dates', meeting.year, meeting.month], {
            queryParamsHandling: 'merge',
            preserveFragment: true,
            queryParams: {
              add: undefined
            },
          });
        }),
    );
  }

  /**
   * Called when a query string changes
   *
   * Opens dialog if needed
   * @param params Query sring parameters
   */
  private onQueryChanged(params: ParamMap) {
    if (params.get('add')) {
      this.openDialog();
    }
  }

  /**
   * Called when the route params changed
   *
   * Expand necessary nodes
   * @param params Route params
   */
  private onRouteChanged(params: ParamMap) {
    const year = params.get('year') ? +params.get('year') : new Date().getFullYear();
    this.expand(year);
  }

  /**
   * Tries to expand the given year
   * @param year The year to expand
   */
  private expand(year: number) {
    const node = this.dataSource.data.find(n => n.item.year === year);
    if (node && !this.treeControl.isExpanded(node)) {
      this.treeControl.expand(node);
    }
  }
}
