import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IEolien } from 'src/interfaces/http/eolien';
import { IOAuthResponse } from 'src/interfaces/http/oauth';
import { ConfigService } from './core/config.service';

@Injectable({
  providedIn: 'root'
})
export class EolienService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  /**
   * Fetch Self
   */
  public fetchMe(): Observable<IOAuthResponse> {
    return this.http.get<IOAuthResponse>(`${this.configService.config.meteole.url}/auth/user/me`);
  }

  public getMyGodchildren(): Observable<IEolien[]> {
    return this.http.get<IEolien[]>(`${this.configService.config.meteole.url}/godfather/godchildren`);
  }

  public getMyFirstGodchild(): Observable<IEolien> {
    return this.http.get<IEolien>(`${this.configService.config.meteole.url}/godfather/first-godchild`);
  }
}
