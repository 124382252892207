<form (submit)="save()" [formGroup]="meteoleForm">
  <mat-dialog-content class="disable-overflow" [ngStyle.lt-sm]="'max-height: 100%'" fxLayout="column"
    fxLayoutGap="10px">
    <div fxFlexFill class="scroll-container">
      <div mat-dialog-title fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
          <div>
            <span i18n="@@meteole-title" class="primary uppercase">Météole</span>
            - {{ data.meteole.snapshotEolienFirstname }} {{ data.meteole.snapshotEolienLastname }}
          </div>
          <div class="right pointer">
            <button type="button" mat-icon-button (click)="loadPreviousMeteole()">
              <fa-icon size="2x" icon="file-import"></fa-icon>
            </button>
          </div>
        </div>

        <div class="uppercase darkgray">
          <!-- Some space on the left equivalent to the close icon above -->
          <div fxFlex="27.5px"></div>
          <ng-container i18n="@@dialog-fill-meteole-period">Période</ng-container> :
          {{ data.meeting.month | monthToDate | date:'MMMM' }}
          {{ data.meeting.year }}
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <div i18n="@@meteole-criteria" class="darkgray">Evaluation des critères de satisfaction</div>

        <div fxLayout="row wrap" fxLayoutGap="10px grid" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <mat-checkbox matInput formControlName="satisfactionMission" color="primary"
            i18n="@@meteole-satisfaction-mission">Mission</mat-checkbox>
          <mat-checkbox matInput formControlName="satisfactionAcknowledgement" color="primary"
            i18n="@@meteole-satisfaction-acknowledgement">Reconnaissance</mat-checkbox>
          <mat-checkbox matInput formControlName="satisfactionBelonging" color="primary"
            i18n="@@meteole-satisfaction-belonging">Appartenance</mat-checkbox>
          <mat-checkbox matInput formControlName="satisfactionCareer" color="primary"
            i18n="@@meteole-satisfaction-career">Carrière</mat-checkbox>
          <mat-checkbox matInput formControlName="satisfactionAccompanying" color="primary"
            i18n="@@meteole-satisfaction-accompanying">Accompagnement RH</mat-checkbox>
        </div>

        <div i18n="@@meteole-actions" class="darkgray">Bilan et plan d'action</div>

        <div i18n="@@meteole-summary">Synthèse</div>
        <mat-radio-group formControlName="summary" fxLayout="row wrap" fxLayoutGap="10px grid" fxLayout.lt-md="column">
          <mat-radio-button fxFlex [value]="0">
            <fa-icon size="2x" icon="angry" class="black"></fa-icon>
          </mat-radio-button>
          <mat-radio-button fxFlex [value]="1">
            <fa-icon size="2x" icon="frown" class="red"></fa-icon>
          </mat-radio-button>
          <mat-radio-button fxFlex [value]="2">
            <fa-icon size="2x" icon="meh" class="orange"></fa-icon>
          </mat-radio-button>
          <mat-radio-button fxFlex [value]="3">
            <fa-icon size="2x" icon="laugh" class="green"></fa-icon>
          </mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
          <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize type="text" placeholder="Remarques"
            (blur)="sanitize(meteoleForm.controls.remarks)" i18n-placeholder="@@meteole-fill-remarks" formControlName="remarks" autocomplete="off"></textarea>
          <mat-hint>
            {{ meteoleForm.value.remarks?.length || 0 }} / {{maxLength}}
          </mat-hint>
        </mat-form-field>
        <mat-form-field>
          <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize type="text" placeholder="Plan d'action parrain"
            (blur)="sanitize(meteoleForm.controls.actionsGodfather)" i18n-placeholder="@@meteole-fill-godfather-action" formControlName="actionsGodfather"
            autocomplete="off"></textarea>
          <mat-hint>
            {{ meteoleForm.value.actionsGodfather?.length || 0 }} / {{maxLength}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div fxFill fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end space-around"
      fxLayoutAlign.lt-sm="stretch space-between">
      <button fxFlex.lt-sm mat-raised-button type="reset" mat-dialog-close i18n="@@cancel">Annuler</button>
      <button fxFlex.lt-sm mat-raised-button color="primary" [disabled]="meteoleForm.invalid" type="submit"
        fxLayoutAlign="center center">
        <mat-spinner *ngIf="loading" color="accent" mode="indeterminate" diameter="24"></mat-spinner>
        <ng-container *ngIf="!loading" i18n="@@save">Sauvegarder</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>
