import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { remove as sanitize } from 'diacritics';
import lunr from 'lunr';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { MeetingService } from 'src/app/services/meeting.service';
import { IMeeting } from 'src/interfaces/http/meeting';
import { IParticipant } from 'src/interfaces/http/participant';

@Component({
  selector: 'eole-dialog-participants',
  templateUrl: './dialog-participants.component.html',
})
export class DialogParticipantsComponent extends SubscribableComponent implements OnInit, OnDestroy {
  /**
   * Loading state
   */
  public loading = false;
  /**
   * Number of attending participants
   */
  public attending = 0;
  /**
   * Number of participants
   */
  public numberOfParticipants = 0;
  /**
   * Number of filled meteoles
   */
  public filled = 0;
  /**
   * Number of meteoles
   */
  public numberOfMeteoles = 0;

  /**
   * Filtered participants
   */
  public filteredParticipants: (IParticipant & { complete: number, total: number })[] = [];
  /**
   * Original participants
   */
  public participants: (IParticipant & { complete: number, total: number })[] = [];
  /**
   * Participant filter input
   */
  public filter$ = new Subject<string>();
  /**
   * Participant filter current value
   */
  public filter: string;

  /**
   * Lunr index
   */
  private index: any;
  /**
   * Participants mapped by id
   */
  private participantMap: { [id: string]: (IParticipant & { complete: number, total: number }) };

  @ViewChild(MatSort, { static: true }) private tableSort: MatSort;

  @ViewChild('table', { static: true })
  private table: MatTable<(IParticipant & { complete: boolean })>;

  constructor(
    public dialogRef: MatDialogRef<DialogParticipantsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IMeeting,
    private meetingService: MeetingService,
  ) {
    super();
  }

  public ngOnInit() {
    this.subs.push(
      this.meetingService.getParticipants(this.data.year, this.data.month).subscribe((participants) => {
        const that = this;
        this.participantMap = {};
        this.participants = participants;
        this.filteredParticipants = participants;
        this.attending = participants.reduce((acc, p) => p.attending ? acc + 1 : acc, 0);
        this.numberOfParticipants = participants.length;
        this.filled = participants.reduce((acc, p) => p.complete + acc, 0);
        this.numberOfMeteoles = participants.reduce((acc, p) => p.total + acc, 0);
        this.index = lunr(function build() {
          this.ref('id');
          this.field('snapshotGodfatherFirstname');
          this.field('snapshotGodfatherLastname');
          this.field('snapshotGodfatherCode');

          // Add only once
          if (!lunr.Pipeline.registeredFunctions.normalise) {
            // Serialize
            this.use((builder) => {
              const pipelineFunction = (token) => token.update(() => sanitize(token.toString()));

              lunr.Pipeline.registerFunction(pipelineFunction, 'normalise');
              builder.pipeline.before(lunr.stemmer, pipelineFunction);
              builder.searchPipeline.before(lunr.stemmer, pipelineFunction);
            });
          }

          participants.forEach((participant) => {
            that.participantMap[participant.id] = participant;
            this.add(participant);
          });
        });
        this.subs.push(this.filter$.pipe(
          debounceTime(150),
          distinctUntilChanged()
        ).subscribe((filter: string) => this.search(filter)));
      }),
    );
  }

  /**
   * Sort participants
   * @param sort Sort criteria
   */
  public sort(sort: Sort) {
    const order = sort.direction === 'desc' ? -1 : 1;
    if (sort.active === 'godfather' || sort.direction === '') {
      this.filteredParticipants.sort((a, b) => a.snapshotGodfatherCode < b.snapshotGodfatherCode ? -order : order);
    } else if (sort.active === 'completion') {
      // NOTE: +1 to avoid dividing by Zero (gives NaN ending up poorly sorted)
      this.filteredParticipants.sort((a, b) => {
        const aratio = (a.total + 1) / (a.complete + 1);
        const bratio = (b.total + 1) / (b.complete + 1);
        if (aratio === bratio) {
          return a.total < b.total ? order : -order;
        }
        return aratio < bratio ? -order : order;
      });
    } else {
      this.filteredParticipants.sort((a, b) => {
        const an = a[sort.active] ? 0 : (a[sort.active] === false ? 1 : 2);
        const bn = b[sort.active] ? 0 : (b[sort.active] === false ? 1 : 2);
        return an < bn ? -order : order;
      });
    }
    this.table.renderRows();
  }

  /**
   * Filter participants
   * @param query Search
   */
  private search(query: string) {
    this.tableSort.sort({ id: '', start: 'asc', disableClear: false });
    if (!query.trim()) {
      this.filteredParticipants = this.participants;
    } else {
      this.filteredParticipants = this.index
        .search(query.split(' ').map(term => `+*${sanitize(term)}*`).join(' '))
        .map((result) => this.participantMap[result.ref]);
    }
    this.table.renderRows();
  }
}
