export interface IUser {
  ID_EOLIEN: number;
  IMAGE: string;
  NOM: string;
  PRENOM: string;
  CODE_EOLIEN: string;
  ROLE: string;
  EMAIL: string;
  PHONE: string;
  AGENCE: string;
  POLE: string;
  DOMAINE: string;
  IS_PARRAIN: boolean;
  IS_COMMERCIAL: boolean;
  IS_DIRECTEUR: boolean;
  rolesEolien: EolienRole[];
}

export enum EolienRole {
  admin = 'admin', // front office admin, can alter meteoles, create meetinggs etc.. (ex: Delphine)
  dev = 'dev', // dev role or god mode, can impersonate anyone (used for dev and debug)
}
