import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule, TRANSLATIONS } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { ConfigService } from 'src/app/services/core/config.service';

import { I18n } from '@ngx-translate/i18n-polyfill';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MarkdownModule } from 'ngx-markdown';

import * as moment from 'moment';

// Provided by webpack
declare const require;

// MODULES
import { AppRoutingModule } from './app-routing.module';
import { FaIconModule } from './fa-icon.module';
import { MaterialModule } from './material.module';

// SERVICES
import { initializeAngularAuthOidcClient } from './services/core/initialize';
import { InterceptorService } from './services/core/interceptor.service';

// DIALOGS
import { DialogAddMeetingComponent } from 'src/app/pages/meteoles/dialog-add-meeting/dialog-add-meeting.component';
import { DialogEditMeetingComponent } from 'src/app/pages/meteoles/dialog-edit-meeting/dialog-edit-meeting.component';
import { DialogChangelogComponent } from './pages/dialog-changelog/dialog-changelog.component';
import { DialogImpersonateComponent } from './pages/dialog-impersonate/dialog-impersonate.component';
import { DialogFillMeteoleComponent } from './pages/meteoles/dialog-fill-meteole/dialog-fill-meteole.component';
import { DialogParticipantsComponent } from './pages/meteoles/dialog-participants/dialog-participants.component';
import { DialogParticipateComponent } from './pages/meteoles/dialog-participate/dialog-participate.component';

// DIRECTIVES & PIPES
import { SideDirective } from './directives/side.directive';
import { BrPipe } from './pipes/br.pipe';
import { HtmlSafePipe } from './pipes/html-safe.pipe';
import { MonthToDatePipe } from './pipes/month-to-date.pipe';

// COMPONENTS
import { AppComponent } from 'src/app/app.component';
import { CenterComponent } from './components/center/center.component';
import { HeaderComponent } from './components/header/header.component';
import { MeteoleAdminTableComponent } from './components/meteole-admin-table/meteole-admin-table.component';
import { MeteoleTableComponent } from './components/meteole-table/meteole-table.component';
import { DefaultComponent } from './layouts/default/default.component';
import { MainComponent } from './layouts/main/main.component';
import { SidenavComponent } from './layouts/sidenav/sidenav.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LostComponent } from './pages/lost/lost.component';
import { MeteoleContentComponent } from './pages/meteoles/meteole-content/meteole-content.component';
import { MeteoleDispatchComponent } from './pages/meteoles/meteole-dispatch/meteole-dispatch.component';
import { MeteoleEolienContentComponent } from './pages/meteoles/meteole-eolien-content/meteole-eolien-content.component';
import { MeteoleKpiComponent } from './pages/meteoles/meteole-kpi/meteole-kpi.component';
import { MeteoleListComponent } from './pages/meteoles/meteole-list/meteole-list.component';
import { MeteoleSessionComponent } from './pages/meteoles/meteole-session/meteole-session.component';
import { MeteoleSideDatesComponent } from './pages/meteoles/meteole-side/dates/meteole-side-dates.component';
import { MeteoleSideEoliensComponent } from './pages/meteoles/meteole-side/eoliens/meteole-side-eoliens.component';
import { MeteoleSideComponent } from './pages/meteoles/meteole-side/meteole-side.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,

    // Layout
    SidenavComponent,
    DefaultComponent,

    // Components
    HeaderComponent,
    CenterComponent,
    MeteoleAdminTableComponent,
    MeteoleTableComponent,

    // Dialogs
    DialogAddMeetingComponent,
    DialogChangelogComponent,
    DialogEditMeetingComponent,
    DialogImpersonateComponent,
    DialogFillMeteoleComponent,
    DialogParticipantsComponent,
    DialogParticipateComponent,

    // Directives & Pipes
    SideDirective,
    MonthToDatePipe,
    BrPipe,
    HtmlSafePipe,

    // Page
    DashboardComponent,

    MeteoleListComponent,
    MeteoleSideComponent,
    MeteoleSideDatesComponent,
    MeteoleSideEoliensComponent,
    MeteoleContentComponent,
    MeteoleKpiComponent,
    MeteoleDispatchComponent,
    MeteoleEolienContentComponent,
    MeteoleSessionComponent,

    LostComponent,
  ],
  imports: [
    AuthModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    FaIconModule,
    FlexLayoutModule,
    MaterialModule,
    ChartsModule,

    MarkdownModule.forRoot({ loader: HttpClient }),

    AppRoutingModule,
  ],
  entryComponents: [
    DialogAddMeetingComponent,
    DialogChangelogComponent,
    DialogEditMeetingComponent,
    DialogImpersonateComponent,
    DialogFillMeteoleComponent,
    DialogParticipantsComponent,
    DialogParticipateComponent,
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAngularAuthOidcClient,
      deps: [OidcConfigService, ConfigService],
      multi: true,
    },
    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        const localeMap = {
          en: 'en',
          'en-GB': 'en',
          'en-US': 'en',
          fr: 'fr',
        };
        locale = localeMap[locale] || 'en'; // default to english if no locale provided
        moment.locale(locale);
        return require(`raw-loader!../locales/messages.${locale}.xlf`).default;
      },
      deps: [LOCALE_ID]
    },
    I18n,
    ThemeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
