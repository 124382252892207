// TODO: what to do if no config is found ? oh snap page ?
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { OidcConfigService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Config } from 'src/interfaces/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Runtime configuration
   */
  public get config(): Config {
    return this.privateConfig;
  }

  /**
   * Runtime configuration
   */
  public set config(config: Config) {
    this.privateConfig = config;
  }

  /**
   * Has the config loaded
   */
  public get loaded(): boolean {
    return this.privateLoaded;
  }

  private privateConfig: Config;
  private privateLoaded: boolean;

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * load the api config and build oidc config
   */
  public loadConfigs(oidcConfigService: OidcConfigService): Promise<void> {
    return this.http.get<Config>(`${environment.api}/config/`)
      .pipe(
        map((config: Config): void => {
          this.config = config;
          this.privateLoaded = true;

          return oidcConfigService.withConfig({
            stsServer: `${config.keycloak.url}/realms/${config.keycloak.alliance4URealm}`,
            redirectUrl: `${config.keycloak.redirectUrl}`,
            postLogoutRedirectUri: `${config.keycloak.postLogoutRedirectUri}`,
            clientId: `${config.keycloak.clientId}`,
            scope: 'openid email offline_access',
            responseType: 'code',
            useRefreshToken: true,
            // No nonce is provided when a acces token is issued using a refresh token using keycloak as a provider
            // See issue: https://github.com/damienbod/angular-auth-oidc-client/issues/552
            ignoreNonceAfterRefresh: true,
            startCheckSession: true,
          }) as unknown as void;
        })
      ).toPromise();
  }
}
