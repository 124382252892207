import { Component, Input } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';

import { IMeteoleSession } from 'src/interfaces/http/meteole';

@Component({
  selector: 'eole-meteole-admin-table',
  templateUrl: './meteole-admin-table.component.html'
})
export class MeteoleAdminTableComponent {
  public headers = [
    'eolien-title',
    'criteria-title',
    'actions-title'
  ];
  public columns = [
    'covered',
    'godfather',
    'godchild',
    'satisfactionMission',
    'satisfactionAccompanying',
    'satisfactionAcknowledgement',
    'satisfactionBelonging',
    'satisfactionCareer',
    'summary',
    'remarks',
    'actionsGodfather',
    'actionsMeeting'
  ];

  public maxLength = 500;

  @Input()
  public dataSource: MatTableDataSource<IMeteoleSession>;

  constructor() { }

  public sanitize(meteole: IMeteoleSession,
                  fieldName: 'remarks' | 'actionsGodfather' | 'actionsMeeting') {
    if (!meteole[fieldName]) {
      meteole[fieldName] = null;
    } else {
      meteole[fieldName] = meteole[fieldName].split('\n').map((line: string) => line.replace(/\s+$/g, '')).join('\n').replace(/\s+$/g, '');
    }
  }

  public track(index: number, item: IMeteoleSession) {
    return item.eolienId;
  }
}
