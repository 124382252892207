import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eole-center',
  templateUrl: './center.component.html',
})
export class CenterComponent implements OnInit {

  constructor() { }

  public ngOnInit() { }
}
