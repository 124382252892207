<mat-dialog-content>
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
    <span i18n="@@dialog-changelog">Versions</span>
  </h2>
  <markdown ngPreserveWhitespaces src="CHANGELOG.md"></markdown>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions>
  <div fxFill fxLayout="row" fxLayoutGap="10px">
    <button fxFlex mat-raised-button type="reset" mat-dialog-close i18n="@@leave">Fermer</button>
  </div>
</mat-dialog-actions>