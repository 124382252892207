import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { PermissionService } from 'src/app/services/core/permission.service';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

@Component({
  selector: 'eole-meteole-side',
  templateUrl: './meteole-side.component.html',
  styleUrls: ['./meteole-side.component.css'],
})
export class MeteoleSideComponent extends SubscribableComponent implements OnInit {
  /**
   * Grouping kind
   */
  public kind: 'dates' | 'eoliens';

  constructor(
    private route: ActivatedRoute,
    public perms: PermissionService,
  ) {
    super();
  }

  public ngOnInit() {
    this.subs.push(
      // Listen for route changes
      this.route.paramMap.subscribe(params => this.onRouteChanged(params)),
    );
  }

  /**
   * Handle route param changes
   * @param params Route params
   */
  private onRouteChanged(params: ParamMap) {
    this.kind = params.get('kind') as 'dates' | 'eoliens';
  }
}
