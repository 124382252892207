import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

/**
 * Sidenav service
 * Application wide navbar control
 */
@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  public drawer?: MatSidenav;
}
