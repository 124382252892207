import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, delay, finalize } from 'rxjs/operators';
import { EolienService } from 'src/app/services/eolien.service';

import { MeetingService } from 'src/app/services/meeting.service';

/**
 * Automatically go to a resource or show empty
 */
@Component({
  selector: 'eole-meteole-dispatch',
  templateUrl: './meteole-dispatch.component.html',
})
export class MeteoleDispatchComponent implements OnInit {
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private meetingService: MeetingService,
    public eolienService: EolienService,
  ) { }

  public async ngOnInit() {
    const kind = this.route.parent.snapshot.paramMap.get('kind') as 'dates' | 'eoliens' | undefined;
    switch (kind) {
      case 'dates':
        this.goToClosestMeteole();
        break;
      case 'eoliens':
        this.goToFirstGodchild();
        break;
      default:
        // await this.router.navigate(['..'], { relativeTo: this.route });
        // this.goToClosestMeteole();
        break;
    }
  }

  public goToClosestMeteole() {
    this.meetingService.getClosestMeeting()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result) => {
        this.loading = true;
        if (result.month === null || result.year === null) { return; }
        this.router.navigate([result.year, result.month], { relativeTo: this.route });
      });
  }

  public goToFirstGodchild() {
    this.eolienService.getMyFirstGodchild()
      .pipe(
        delay(750),
        finalize(() => this.loading = false)
      )
      .subscribe((godchild) => {
        this.router.navigate([godchild.id], { relativeTo: this.route });
      });
  }

  public retry() {
    this.loading = true;
    this.ngOnInit();
  }
}
