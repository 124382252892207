<form (submit)="send()" [formGroup]="meetingForm">
  <mat-dialog-content>
    <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
      <span i18n="@@add-meeting-dialog-title">Ajouter une météole</span>
    </h2>

    <div fxLayout="column" fxLayoutGap="5px">
      <mat-form-field>
        <mat-label i18n="@@add-meeting-year-month-placeholder">Mois de la méteole</mat-label>
        <!-- Displayed input -->
        <input matInput [readonly]="true" [value]="date">
        <!-- Hidden data binding -->
        <input matInput [hidden]="true" [matDatepicker]="picker_date" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #picker_date [touchUi]="(isHandset$ | async)" startView="year"
          (yearSelected)="dateChangedHandler($event)" (monthSelected)="dateChangedHandler($event, picker_date, true)">
        </mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label i18n="@@add-meeting-closure-label">Date de cloture</mat-label>
        <input matInput [matDatepickerFilter]="weekdaysFilter" [matDatepicker]="picker_end"
          [placeholder]="lastThursdayOfMonth | date:'shortDate'" formControlName="closure" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker_end"></mat-datepicker-toggle>
        <mat-datepicker color="primary" [touchUi]="(isHandset$ | async)" #picker_end></mat-datepicker>
      </mat-form-field>

      <!-- Toggle meeting fields -->
      <mat-checkbox formControlName="hasMeeting" i18n="@@add-meeting-has-meeting">Réunion</mat-checkbox>

      <mat-form-field>
        <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize type="text" placeholder="Description"
          i18n-placeholder="@@add-meeting-description-placeholder" formControlName="description" autocomplete="off"></textarea>
        <mat-hint>
          {{ meetingForm.value.description?.length || 0 }} / {{maxLength}}
        </mat-hint>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <!-- Actions -->
  <mat-dialog-actions>
    <div fxFill fxLayout="row" fxLayoutGap="10px">
      <button fxFlex mat-raised-button type="reset" mat-dialog-close i18n="@@cancel">Annuler</button>
      <button fxFlex mat-raised-button color="primary" [disabled]="meetingForm.invalid" type="submit"
        fxLayoutAlign="center center">
        <mat-spinner *ngIf="loading" color="accent" mode="indeterminate" diameter="24"></mat-spinner>
        <ng-container *ngIf="!loading" i18n="@@save">Sauvegarder</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>
