<form (submit)="send()" [formGroup]="impersonateForm">
  <mat-dialog-content>
    <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
      <span i18n="@@dialog-impersonate">Se connecter en tant que:</span>
    </h2>

    <div fxLayout="column" fxLayoutGap="5px">
      <mat-form-field>
        <mat-label i18n="@@dialog-impersonate-label">Utilisateur</mat-label>
        <input matInput placeholder="Choose a user" [matAutocomplete]="auto" formControlName='mail'>
        <mat-hint i18n="@dialog-impersonate-hint">Tapez un nom d'éolien pour rechercher</mat-hint>
      </mat-form-field>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let user of (targets | async)?.results" [value]="user">
          <span>{{ user }}</span>
        </mat-option>
      </mat-autocomplete>
    </div>
  </mat-dialog-content>

  <!-- Actions -->
  <mat-dialog-actions>
    <div fxFill fxLayout="row" fxLayoutGap="10px">
      <button fxFlex mat-raised-button type="reset" mat-dialog-close i18n="@@cancel">Annuler</button>
      <button fxFlex mat-raised-button color="primary" [disabled]="impersonateForm.invalid" type="submit"
        fxLayoutAlign="center center">
        <ng-container i18n="@@dialog-impersonate-action">Incarner</ng-container>
      </button>
    </div>
  </mat-dialog-actions>
</form>