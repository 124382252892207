import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PermissionService } from 'src/app/services/core/permission.service';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { EolienService } from 'src/app/services/eolien.service';

import { MeteoleService } from 'src/app/services/meteole.service';
import { IEolien } from 'src/interfaces/http/eolien';
import { IMeeting } from 'src/interfaces/http/meeting';
import { IMeteole, IMeteoleMeeting } from 'src/interfaces/http/meteole';
import { DialogFillMeteoleComponent } from '../dialog-fill-meteole/dialog-fill-meteole.component';

@Component({
  selector: 'eole-meteole-eolien-content',
  templateUrl: './meteole-eolien-content.component.html',
})
export class MeteoleEolienContentComponent extends SubscribableComponent implements OnInit {

  /**
   * Side nav loading
   */
  public sideLoading = true;

  /**
   * Wether we listen for query params or not
   */
  private isListeningForQueryParams = false;

  /**
   * Content loading
   */
  public loading = true;

  /**
   * Current mobile|desktop state
   */
  public isHandset: boolean;
  /**
   * Mobile|desktop state observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  /**
   * Eolien selected id
   */
  public eolien: number;

  /**
   * Fetched godchildren
   */
  public godchildren: IEolien[] = [];

  /**
   * Meteoles
   */
  public meteoles: IMeteole[] = [];
  public meteoleItems: MatTableDataSource<IMeteoleMeeting> = new MatTableDataSource([]);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public eolienService: EolienService,
    private meteoleService: MeteoleService,
    public perms: PermissionService,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit() {
    this.subs.push(
      this.isHandset$.subscribe((isHandset) => this.isHandset = isHandset),
      // Listen for route changes
      this.route.paramMap.subscribe(params => this.onRouteChanged(params)),
    );
  }

  /**
   * Handle route param changes
   * @param params Route params
   */
  private async onRouteChanged(params: ParamMap) {
    this.loading = true;
    this.eolien = +params.get('eolien');

    if (this.godchildren.length === 0) {
      this.eolienService.getMyGodchildren().subscribe(gdc => {
        this.godchildren = gdc;
        this.sideLoading = false;
      });
    }

    this.meteoleService.getGodchildMeteoles(this.eolien).subscribe(m => {
      this.meteoles = m;

      this.meteoleItems.data = this.meteoles.map(meteole => ({
        meteole,
        editable: this.canEdit(meteole.meeting, meteole),
        meeting: meteole.meeting,
      }));
      this.loading = false;

      if (!this.isListeningForQueryParams) {
        this.isListeningForQueryParams = true;
        this.subs.push(
          this.route.queryParamMap.subscribe(query => this.onQueryChanged(query)),
        );
      }

    });
  }

  /**
   * Handle query param changes
   * @param params Query params
   */
  private async onQueryChanged(params: ParamMap) {
    if (params.get('edit')) {
      this.openMeteoleFillDialog(+params.get('edit'));
    }
  }

  public canEdit(meeting: IMeeting, meteole: IMeteole) {
    return moment(meeting.closure).startOf('day').diff(moment()) > 0 && this.perms.can('update', 'meteole', meteole) && !meeting.closed;
  }

  public getEolienObject() {
    return this.godchildren.find(g => g.id === this.eolien);
  }

  public onMeteoleEditClicked(meteole: IMeteole) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      preserveFragment: true,
      queryParams: {
        edit: meteole.meetingId,
      },
    });
  }

  private openMeteoleFillDialog(meetingId: number) {
    const meteole = this.meteoles.find(m => m.meetingId === meetingId);
    const dialogRef = this.dialog.open(DialogFillMeteoleComponent, this.isHandset ? {
      data: {
        meteole: JSON.parse(JSON.stringify(meteole)) as IMeteole,
        meeting: JSON.parse(JSON.stringify(meteole.meeting)) as IMeeting,
      },
      disableClose: true,
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
    } : {
        data: {
          meteole: JSON.parse(JSON.stringify(meteole)) as IMeteole,
          meeting: JSON.parse(JSON.stringify(meteole.meeting)) as IMeeting,
        },
        disableClose: true,
        maxHeight: '100vh',
        width: '600px',
      });

    dialogRef.afterClosed().subscribe((newMeteole: IMeteole) => {
      if (newMeteole) {
        Object.assign(meteole, newMeteole);
      }

      this.router.navigate([], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams: {
          edit: undefined
        }
      });
    });
  }
}
