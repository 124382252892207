
import { OidcConfigService } from 'angular-auth-oidc-client';
import { ConfigService } from 'src/app/services/core/config.service';

/**
 * Initialize the config of the app
 * @param config The configuration service
 */
export function initializeAngularAuthOidcClient(
  oidcConfigService: OidcConfigService,
  configService: ConfigService
): () =>  Promise<void> {
  return () => {
    return configService.loadConfigs(oidcConfigService);
  };
}
