<mat-dialog-content>
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
    <div>
      <ng-container i18n="@@dialog-participate-title">Météole de</ng-container> {{ data.month | monthToDate | date:'MMMM' | lowercase }} {{ data.year }}
    </div>
  </h2>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions>
  <div fxFill fxLayout="row" fxLayoutGap="10px">
    <button fxFlex mat-raised-button color="warn" type="button" fxLayoutAlign="center center" (click)="send(false)">
      <mat-spinner *ngIf="loading" color="accent" mode="indeterminate" diameter="24"></mat-spinner>
      <ng-container *ngIf="!loading" i18n="@@dialog-participate-not-attending">Absent</ng-container>
    </button>
    <button fxFlex mat-raised-button color="primary" type="button" fxLayoutAlign="center center" (click)="send(true)">
      <mat-spinner *ngIf="loading" color="accent" mode="indeterminate" diameter="24"></mat-spinner>
      <ng-container *ngIf="!loading" i18n="@@dialog-participate-attending">Présent</ng-container>
    </button>
  </div>
</mat-dialog-actions>
