<div fxFill fxLayout="column" class="scroll-container">
  <mat-toolbar>
    <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="uppercase" i18n="@@meteole-title">Météole</h2>
      <div fxLayoutGap="10px">
        <fa-icon routerLink="/meteoles/dates" routerLinkActive="primary" class="darkgray" [icon]="['far', 'calendar-alt']">
        </fa-icon>
        <fa-icon routerLink="/meteoles/eoliens" routerLinkActive="primary" class="darkgray" icon="user-friends"></fa-icon>
        <!-- <fa-icon class="darkgray" icon="user-friends" matTooltip="Revenez plus tard pour de nouvelles fonctionnalités"
          i18n-matTooltip="@@not-yet-implemented"></fa-icon>-->
        <fa-icon *ngIf="perms.can('create', 'session')" routerLink="/meteoles/kpi" routerLinkActive="primary" class="darkgray" icon="chart-bar"></fa-icon>
      </div>
    </div>
  </mat-toolbar>

  <ng-content></ng-content>
</div>
