import { Injectable } from '@angular/core';
import * as localForage from 'localforage';

/**
 * Stores data on the client side
 *
 * Use with caution as not to leak any client data
 */
@Injectable({
  providedIn: 'root',
})
export class StoreService {

  /**
   * LocalForage is a library that gives access to local storage on most browsers
   * It uses IndexDb or fallback to other kinds of storages if not available
   *
   * Access to data in the storage is async and uses promises
   * Use with caution ! Do not store sensitive data inside that storage.
   */

  public accounts: LocalForage;
  public meta: LocalForage;

  constructor() {
    this.meta = localForage.createInstance({
      name: 'intranet',
      version: 1.0,
      size: 4980736,
      storeName: 'meta',
      description: 'Intranet meta-data',
    });
  }
}
