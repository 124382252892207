import { Component } from '@angular/core';

import { SidenavService } from 'src/app/services/core/sidenav.service';

/**
 * Default layout with a header
 */
@Component({
  selector: 'eole-default',
  templateUrl: './default.component.html',
})
export class DefaultComponent {
  constructor(public sidenav: SidenavService) { }
}
