import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { IMeeting } from 'src/interfaces/http/meeting';
import { IParticipant, IParticipation } from 'src/interfaces/http/participant';
import { ConfigService } from './core/config.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  /**
   * Get the list of years of meetings
   */
  public getMeetingYears(): Observable<number[]> {
    return this.http.get<number[]>(`${this.configService.config.meteole.url}/meetings`);
  }

  /**
   * Gets the meetings for the given year
   * @param year The target year
   */
  public getMeetingsOfYear(year: number):
    Observable<(IMeeting & { participants: [{ attending: boolean }?], meteoles: { eolienId: number, status: string }[] })[]> {
    return this.http.get<(IMeeting & { participants: [{ attending: boolean }?], meteoles: { eolienId: number, status: string }[] })[]>(
      `${this.configService.config.meteole.url}/meetings/${year}`
    );
  }

  /**
   * Gets the meeting for the given year and month
   * @param year The target year
   * @param month The target month
   */
  public getMeetingByDate(year: number, month: number): Observable<IMeeting> {
    return this.http.get<IMeeting>(`${this.configService.config.meteole.url}/meetings/${year}/${month}`);
  }

  /**
   * Gets the participation of the current user for the meeting at the given year and month
   * @param year The target year
   * @param month The target month
   */
  public getParticipation(year: number, month: number): Observable<IParticipant> {
    return this.http.get<IParticipant>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/participation`);
  }

  /**
   * Sets the participation of the current user for the meeting at the given year and month
   * @param year The target year
   * @param month The target month
   * @param participation Wether to participate or not
   */
  public updateParticipation(year: number, month: number, participation: boolean, godfatherId?: number): Observable<{ success: boolean }> {
    const params = Number.isFinite(godfatherId) ? { godfather: godfatherId.toString() } : {};
    return this.http.put<{ success: boolean }>(
      `${this.configService.config.meteole.url}/meetings/${year}/${month}/participation`,
      { attending: participation },
      { params },
    );
  }

  /**
   * Gets the meeting for the given year and month
   * @param year The target year
   * @param month The target month
   */
  public getParticipants(year: number, month: number): Observable<IParticipation[]> {
    return this.http.get<IParticipation[]>(
      `${this.configService.config.meteole.url}/meetings/${year}/${month}/participants`);
  }

  /**
   * Create a meeting
   */
  public createMeeting(meeting: IMeeting): Observable<IMeeting> {
    return this.http.post<IMeeting>(`${this.configService.config.meteole.url}/meetings/${meeting.year}/${meeting.month}`, meeting);
  }

  /**
   * Update a meeting
   */
  public updateMeeting(meeting: IMeeting): Observable<IMeeting> {
    return this.http.put<IMeeting>(`${this.configService.config.meteole.url}/meetings/${meeting.year}/${meeting.month}`, meeting);
  }

  /**
   * Close a meeting
   */
  public closeMeeting(meeting: IMeeting): Observable<IMeeting> {
    return this.http.post<IMeeting>(`${this.configService.config.meteole.url}/meetings/${meeting.year}/${meeting.month}/close`, {});
  }

  /**
   * Gets the closest meeting date
   */
  public getClosestMeeting(): Observable<{ month: null | number, year: null | number }> {
    return this.http.get<{ month: null | number, year: null | number }>(`${this.configService.config.meteole.url}/meetings/closest`);
  }
}
