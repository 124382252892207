import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { finalize } from 'rxjs/operators';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';

import { EventService } from 'src/app/services/core/event.service';
import { MeteoleService } from 'src/app/services/meteole.service';
import { IMeeting } from 'src/interfaces/http/meeting';
import { IMeteole } from 'src/interfaces/http/meteole';

@Component({
  selector: 'eole-dialog-fill-meteole',
  templateUrl: './dialog-fill-meteole.component.html',
})
export class DialogFillMeteoleComponent extends SubscribableComponent implements OnInit {
  /**
   * Loading state
   */
  public loading = false;

  public meteoleForm: FormGroup;

  public maxLength = 500;

  constructor(
    public dialogRef: MatDialogRef<DialogFillMeteoleComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { meteole: IMeteole, meeting: IMeeting },
    private i18n: I18n,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private eventService: EventService,
    private meteoleService: MeteoleService,
  ) {
    super();
  }

  public ngOnInit() {
    this.fillForm(this.data.meteole);
  }

  public fillForm(meteole: IMeteole) {
    this.meteoleForm = this.fb.group({
      summary: [meteole.summary, [Validators.required, Validators.min(0), Validators.max(3)]],
      satisfactionMission: [meteole.satisfactionMission, []],
      satisfactionAccompanying: [meteole.satisfactionAccompanying, []],
      satisfactionAcknowledgement: [meteole.satisfactionAcknowledgement, []],
      satisfactionBelonging: [meteole.satisfactionBelonging, []],
      satisfactionCareer: [meteole.satisfactionCareer, []],
      remarks: [meteole.remarks, [Validators.maxLength(this.maxLength)]],
      actionsGodfather: [meteole.actionsGodfather, [Validators.maxLength(this.maxLength)]],
    });
  }

  public save() {
    this.loading = true;
    if (!this.meteoleForm.value.remarks) {
      this.meteoleForm.value.remarks = null;
    }
    if (!this.meteoleForm.value.actionsGodfather) {
      this.meteoleForm.value.actionsGodfather = null;
    }
    this.subs.push(
      this.meteoleService.updateMeteole(this.data.meteole.eolienId, this.data.meteole.meetingId, this.meteoleForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.success) {
            this.snackbar.open(
              this.i18n({ id: 'meteole-save-success', value: 'Météole enregistrée' }),
              '',
              { duration: 2000 },
            );
            this.eventService.meteoleSaved.next({
              ...this.data.meteole,
              ...this.meteoleForm.value,
              status: 'filled',
            });
            this.dialogRef.close(this.meteoleForm.value);
          } else {
            this.snackbar.open(
              this.i18n({ id: 'meteole-save-failure', value: 'Impossible d\'enregistrer la météole' }),
              '',
              { duration: 2000 },
            );
          }
        }),
    );
  }

  public sanitize(control: AbstractControl) {
    if (!control.value) {
      control.setValue(null);
    } else {
      control.setValue(control.value.split('\n').map((line: string) => line.replace(/\s+$/g, '')).join('\n').replace(/\s+$/g, ''));
    }
  }

  public loadPreviousMeteole() {
    let year = this.data.meeting.year;
    let month = this.data.meeting.month;

    if (month === 1) {
      year--;
    } else {
      month--;
    }

    this.meteoleService.getGodchildMeteolesByDate(year, month, this.data.meteole.eolienId)
    .subscribe(previousMeteole => {
      if (previousMeteole) {
        this.fillForm(previousMeteole);
      } else {
        this.snackbar.open(
          'Pas de météole le mois précédent',
          '',
          { duration: 2000 });
      }
    });
  }
}
