import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDatepicker } from '@angular/material/datepicker';

import { Observable, Subscription } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';

import { ChartDataSets, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { Label } from 'ng2-charts';

import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { KpiService } from 'src/app/services/kpi.service';
import { IKpi } from 'src/interfaces/http/kpi';

@Component({
  selector: 'eole-meteole-kpi',
  templateUrl: './meteole-kpi.component.html',
  styleUrls: ['./meteole-kpi.component.css'],
  providers: [DatePipe],
})
export class MeteoleKpiComponent extends SubscribableComponent implements OnInit {
  /**
   * Loading state
   */
  public loading = true;
  /**
   * Mobile/Desktop observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  /**
   * Form
   */
  public filterForm: FormGroup;

  /**
   * Get the month and year of the from filter (for display purposes)
   */
  public get dateFrom(): string {
    return this.datePipe.transform((this.filterForm.value.from || moment()).local(), 'MMMM / yyyy').toLocaleUpperCase();
  }
  /**
   * Get the month and year of the to filter (for display purposes)
   */
  public get dateTo(): string {
    return this.datePipe.transform((this.filterForm.value.to || moment()).local(), 'MMMM / yyyy').toLocaleUpperCase();
  }

  public summaryChartOptions: ChartOptions = {
    title: {
      text: 'Feux',
      display: true,
      fontSize: 16,
    },
    responsive: true,
    legend: { position: 'bottom' },
  };
  public summaryChartLabels: Label[] = [];
  public summaryChartData: ChartDataSets[] = [];

  public globalSatisfactionChartOptions: ChartOptions = {
    title: {
      text: 'Taux de satisfaction',
      display: true,
      fontSize: 16,
    },
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          callback: (value: number) => value + ' %',
        },
      }],
    },
  };
  public globalSatisfactionChartLabels: Label[] = [];
  public globalSatisfactionChartData: ChartDataSets[] = [];

  public satisfactionChartOptions: ChartOptions = {
    title: {
      text: 'Satisfactions',
      display: true,
      fontSize: 16,
    },
    responsive: true,
    legend: { position: 'bottom' },
  };
  public satisfactionChartLabels: Label[] = [];
  public satisfactionChartData: ChartDataSets[] = [];

  public indicesChartOptions: ChartOptions = {
    title: {
      text: 'Nos indicateurs',
      display: true,
      fontSize: 16,
    },
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          callback: (value: number) => value + ' %',
        },
      }],
    },
  };
  public indicesChartLabels: Label[] = [];
  public indicesChartData: ChartDataSets[] = [];

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe,
    private kpiService: KpiService,
    ) {
      super();
    }

  public ngOnInit() {
    // Generate default form
    this.filterForm = this.fb.group({
      from: [moment().startOf('year'), [Validators.required]],
      to: [moment().endOf('year'), [Validators.required]],
    });

    this.subs.push(this.getData());
  }

  public withTitle(title: string, opts: ChartOptions) {
    const clone = JSON.parse(JSON.stringify(opts)) as ChartOptions;
    clone.title = {
      display: true,
      text: title,
    };
    return clone;
  }

  /**
   * Handle meteole date change (Display and value)
   * @param date Selected date
   * @param controlName Control name
   * @param datepicker Origin datepicker
   * @param close Wether to close the datepicker or not
   */
  public dateChangedHandler(date: moment.Moment, controlName: 'from' | 'to', datepicker?: MatDatepicker<moment.Moment>, close = false) {
    const ctrlValue = this.filterForm.value[controlName].local();
    ctrlValue.year(date.local().year());
    ctrlValue.month(date.local().month());
    ctrlValue.startOf('month');
    this.filterForm.controls[controlName].setValue(ctrlValue);

    if (close && datepicker) {
      datepicker.close();
      this.subs.push(this.getData());
    }
  }

  private getData(): Subscription {
    this.loading = true;
    return this.kpiService.getKpi(moment(this.filterForm.value.from), moment(this.filterForm.value.to))
    .pipe(finalize(() => { this.loading = false; }))
    .subscribe((data) => {
      this.parseData(data);
    });
  }

  private parseData(data: IKpi[]) {
    const labels: Label[] = [];

    const black: number[] = [];
    const red: number[] = [];
    const orange: number[] = [];
    const green: number[] = [];
    const unknown: number[] = [];

    const satisfactionAccompanying: number[] = [];
    const satisfactionAcknowledgement: number[] = [];
    const satisfactionBelonging: number[] = [];
    const satisfactionCareer: number[] = [];
    const satisfactionMission: number[] = [];

    const satisfaction: number[] = [];
    const satisfactionManager: number[] = [];
    const satisfactionSupport: number[] = [];

    const participation: number[] = [];
    const collect: number[] = [];
    const filled: number[] = [];
    const covered: number[] = [];

    // Sort date ascending
    data.sort((a, b) => moment(a.meetingDate).diff(moment(b.meetingDate)));
    for (const kpi of data) {
      labels.push(moment(kpi.meetingDate).format('MMMM YYYY'));

      black.push(kpi.black);
      red.push(kpi.red);
      orange.push(kpi.orange);
      green.push(kpi.green);
      unknown.push(kpi.unknown);

      satisfactionAccompanying.push(kpi.satisfactionAccompanying);
      satisfactionAcknowledgement.push(kpi.satisfactionAcknowledgement);
      satisfactionBelonging.push(kpi.satisfactionBelonging);
      satisfactionCareer.push(kpi.satisfactionCareer);
      satisfactionMission.push(kpi.satisfactionMission);

      satisfaction.push(
        Math.round((-2 * kpi.black - kpi.red + 0.5 * kpi.orange + kpi.green) / kpi.eoliens * 1000) / 10
      );
      satisfactionManager.push(
        Math.round((-2 * kpi.blackManager - kpi.redManager + 0.5 * kpi.orangeManager + kpi.greenManager) / kpi.manager * 1000) / 10
      );
      satisfactionSupport.push(
        Math.round((-2 * kpi.blackSupport - kpi.redSupport + 0.5 * kpi.orangeSupport + kpi.greenSupport) / kpi.support * 1000) / 10
      );

      participation.push(
        Math.round(kpi.attending / kpi.godfathers * 1000) / 10,
      );
      collect.push(
        Math.round(kpi.godfatherCollecting / kpi.godfathers * 1000) / 10,
      );
      filled.push(
        Math.round((kpi.eoliens - kpi.unknown) / kpi.eoliens * 1000) / 10,
      );
      covered.push(
        Math.round(kpi.covered / kpi.eoliens * 1000) / 10,
      );
    }

    this.summaryChartLabels = labels;
    this.satisfactionChartLabels = labels;
    this.globalSatisfactionChartLabels = labels;
    this.indicesChartLabels = labels;

    this.summaryChartData = [
      { data: black, label: 'Noirs', ...this.color('#000000'), barPercentage: 0.6, stack: 'eolien' },
      { data: red, label: 'Rouges', ...this.color('#ff0000'), barPercentage: 0.6, stack: 'eolien' },
      { data: orange, label: 'Oranges', ...this.color('#ffc000'), barPercentage: 0.6, stack: 'eolien' },
      { data: green, label: 'Verts', ...this.color('#70ad47'), barPercentage: 0.6, stack: 'eolien' },
      { data: unknown, label: 'Non renseigné', ...this.color('#aaaaaa'), barPercentage: 0.6, stack: 'eolien' },
    ];

    this.satisfactionChartData = [
      { data: satisfactionAccompanying, label: 'Accompagnement', ...this.color('#ff9da6'), barPercentage: 0.6, stack: 'eolien' },
      { data: satisfactionAcknowledgement, label: 'Reconnaissance', ...this.color('#9d755d'), barPercentage: 0.6, stack: 'eolien' },
      { data: satisfactionBelonging, label: 'Appartenance', ...this.color('#666666'), barPercentage: 0.6, stack: 'eolien' },
      { data: satisfactionCareer, label: 'Carrière', ...this.color('#e45756'), barPercentage: 0.6, stack: 'eolien' },
      { data: satisfactionMission, label: 'Mission', ...this.color('#72b7b2'), barPercentage: 0.6, stack: 'eolien' },
    ];

    this.globalSatisfactionChartData = [
      { data: satisfaction, label: 'Satisfaction', ...this.color('#e45756'), fill: false, lineTension: 0 },
      { data: satisfactionManager, label: 'Satisfaction - Manager', ...this.color('#666666'), fill: false, lineTension: 0 },
      { data: satisfactionSupport, label: 'Satisfaction - Support', ...this.color('#72b7b2'), fill: false, lineTension: 0 },
    ];

    this.globalSatisfactionChartOptions.title.text =
      `Taux de satisfaction moyen : ${Math.round(satisfaction.reduce((p, c) => p + c) / satisfaction.length * 10) / 10} %`;

    this.indicesChartData = [
      { data: participation, label: 'Participation des parrains', ...this.color('#ff9da6'), fill: false, lineTension: 0 },
      { data: collect, label: 'Taux de collecte', ...this.color('#666666'), fill: false, lineTension: 0 },
      { data: filled, label: 'Taux de couverture en amont', ...this.color('#72b7b2'), fill: false, lineTension: 0 },
      { data: covered, label: 'Taux de couverture en séance', ...this.color('#e45756'), fill: false, lineTension: 0 },
    ];
  }

  private color(color: string) {
    return {
      backgroundColor: color,
      borderColor: color,
      hoverBackgroundColor: color,
      hoverBorderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
    };
  }

}
