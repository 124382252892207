<mat-dialog-content class="disable-overflow" [ngStyle.lt-sm]="'max-height: 100%'" fxLayout="column" fxLayoutGap="10px">
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <fa-icon mat-dialog-close icon="arrow-left"></fa-icon>
    <div i18n="@@participants-dialog-title">Liste des participants</div>
  </h2>

  <div>
    <ng-container i18n="@@meteole-title">Météole</ng-container> - {{ data.month | monthToDate | date:'MMMM' }}
    {{ data.year }}
  </div>

  <div>
    ({{ attending }} <ng-container i18n="@@participants-present">participants</ng-container> /
    {{ numberOfParticipants }} <ng-container i18n="@@participants-guests">invités</ng-container>)
  </div>

  <div>
    ({{ filled }} / {{ numberOfMeteoles }}
    <ng-container i18n="@@participants-meteoles">météoles</ng-container>)
  </div>

  <mat-form-field>
    <input matInput [(ngModel)]="filter" (ngModelChange)="filter$.next($event)" placeholder="Rechercher"
      i18n-placeholder="@@lookup" autocomplete="off">
  </mat-form-field>

  <div fxFlexFill class="scroll-container">
    <table fxFlexFill #table mat-table matSort [dataSource]="filteredParticipants" (matSortChange)="sort($event)">
      <mat-text-column name="snapshotGodfatherFirstname"></mat-text-column>
      <mat-text-column name="snapshotGodfatherLastname"></mat-text-column>
      <ng-container matColumnDef="attending">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <ng-container i18n="@@dialog-participant-table-attending-header">Participation</ng-container>
        </th>
        <td mat-cell *matCellDef="let participant">
          <ng-container [ngSwitch]="participant.attending">
            <fa-icon icon="check" *ngSwitchCase="true" class="green"></fa-icon>
            <fa-icon icon="times" *ngSwitchCase="false" class="red"></fa-icon>
            <fa-icon icon="question" *ngSwitchDefault class="gray"></fa-icon>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="completion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          <ng-container i18n="@@dialog-participant-table-meteole-header">Météoles</ng-container>
        </th>
        <td mat-cell *matCellDef="let participant">
          <fa-icon *ngIf="participant.complete === participant.total" icon="check" class="green"></fa-icon>
          <fa-icon *ngIf="participant.complete !== participant.total && participant.complete === 0" icon="times"
            class="red"></fa-icon>
          <fa-icon *ngIf="participant.complete !== participant.total && participant.complete > 0" icon="tasks"
            class="orange"></fa-icon>
          {{ participant.complete }} / {{ participant.total }}
        </td>
      </ng-container>

      <ng-container matColumnDef="godfather">
        <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@dialog-participant-table-godfather-header"
          colspan="2">Parrain</th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['godfather', 'attending', 'completion']">
      </tr>
      <tr mat-row
        *matRowDef="let row; columns: ['snapshotGodfatherFirstname', 'snapshotGodfatherLastname', 'attending', 'completion'];">
      </tr>
    </table>
  </div>

</mat-dialog-content>