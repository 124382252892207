<mat-toolbar #toolbar color="primary" fxLayout="row" fxLayoutGap="10px">
  <!-- When has sidenav -->
  <ng-container *ngIf="sidenav.drawer">
    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.drawer.toggle()"
      *ngIf="isHandset$ | async">
      <fa-icon aria-label="Side nav toggle icon" icon="bars"></fa-icon>
    </button>
  </ng-container>

  <!-- Logo -->
  <a routerLink="/" fxLayout="column" fxLayoutAlign="center center">
    <img src="assets/alliance4u.png" alt="Alliance4U" [height]="(isHandset$ | async) ? 56 - 10 : 64 - 10">
  </a>

  <!-- Main navigation -->
  <div fxLayout="row" fxLayoutGap="10px" [class.spaced]="(isHandset$ | async) === false">
    <!-- Météole-icon -->
    <a routerLink="/meteoles" fxLayout="column" fxLayoutAlign="center center">
      <img src="assets/sun.png" alt="meteole" [height]="(isHandset$ | async) ? 56 / 2 : 64 / 2">
    </a>
  </div>

  <!-- Spacer -->
  <div fxFlex="grow"></div>

  <!-- Profile -->
  <ng-container *ngIf="user">
    <span *ngIf="(isHandset$ | async) === false">{{user.PRENOM}} {{user.NOM}}</span>

    <span [matBadge]="notification" matBadgePosition="below after" matBadgeSize="small" matBadgeColor="warn">
      <img mat-icon-button [matMenuTriggerFor]="optionMenu" class="rounded"
        [height]="((isHandset$ | async) ? 56 : 64) / 1.5" [src]="user.IMAGE || defaultImage + '?sz=50'" alt="Profile">
    </span>

    <button mat-icon-button (click)="logout()">
      <fa-icon icon="sign-out-alt" size="lg"></fa-icon>
    </button>

    <mat-menu #optionMenu="matMenu">
      <span mat-menu-item disabled>{{user.PRENOM}} {{user.NOM}}</span>
      <mat-divider></mat-divider>
      <button mat-menu-item fxLayoutGap="4px" [routerLink]="" [queryParams]="{changelog: true}"
        [preserveFragment]="true" queryParamsHandling="merge">
        <fa-icon icon="code-branch" size="lg"></fa-icon>
        <span [matBadge]="notification" matBadgeOverlap="false" matBadgePosition="above after" matBadgeSize="small"
          matBadgeColor="warn">
          {{version}}
        </span>
      </button>
      <!-- TODO: fix impersonate -->
      <button mat-menu-item *ngIf="canImpersonate(user)" fxLayoutGap="4px" [routerLink]=""
        [queryParams]="{impersonate: true}" [preserveFragment]="true" queryParamsHandling="merge">
        <fa-icon class="orange" icon="user-secret" size="lg"></fa-icon>
        <ng-container i18n="@@menu-action-impersonate">Incarner</ng-container>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()" fxLayoutGap="4px">
        <fa-icon icon="sign-out-alt" size="lg"></fa-icon>
        <ng-container i18n="@@menu-action-logout">Déconnexion</ng-container>
      </button>
    </mat-menu>
  </ng-container>
</mat-toolbar>