<div *ngIf="useFilters" fxLayout="column" fxLayoutAlign="start stretch" class="container">
  <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Collaborateur</mat-label>
      <mat-select [(ngModel)]="filter.eolien" (ngModelChange)="applyFilter()">
        <mat-option>-</mat-option>
        <mat-option *ngFor="let eolien of eoliens" [value]="eolien.id">
          {{eolien.firstname}} {{eolien.lastname}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Parrain</mat-label>
      <mat-select [(ngModel)]="filter.godfatherCode" (ngModelChange)="applyFilter()">
        <mat-option>-</mat-option>
        <mat-option *ngFor="let godfather of godfathers" [value]="godfather.code">
          {{godfather.code}} - {{godfather.firstname}} {{godfather.lastname}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Domaines</mat-label>
      <mat-select [(ngModel)]="filter.domain" (ngModelChange)="applyFilter()">
        <mat-option>-</mat-option>
        <mat-option *ngFor="let domain of domains" [value]="domain">
          {{domain}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<table mat-table fxFill #table [dataSource]="items" class="large-table">
  <ng-container *ngIf="showGodfatherCode()" matColumnDef="godfather">
    <th mat-header-cell *matHeaderCellDef>Parrain</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <b
        [matTooltip]="meteoleItem.meteole.snapshotGodfatherFirstname+' '+meteoleItem.meteole.snapshotGodfatherLastname">
        {{ meteoleItem.meteole.snapshotGodfatherCode }}
      </b>
    </td>
  </ng-container>

  <ng-container matColumnDef="c-empty-title" *ngIf="showYearMonths()">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <button *ngIf="meteoleItem.editable" mat-icon-button (click)="this.editClick.emit(meteoleItem.meteole)">
        <fa-icon icon="edit"></fa-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="year" *ngIf="showYearMonths()">
    <th mat-header-cell *matHeaderCellDef>Année</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <b> {{ meteoleItem.meeting.year }} </b>
    </td>
  </ng-container>

  <ng-container matColumnDef="month" *ngIf="showYearMonths()">
    <th mat-header-cell *matHeaderCellDef>Mois</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <b style="text-transform: capitalize;"> {{ meteoleItem.meeting.month | monthToDate | date:'MMMM' }} </b>
    </td>
  </ng-container>

  <ng-container *ngIf="showEolienInfo()" matColumnDef="godchild">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-eolien-header">Collaborateur</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <button *ngIf="meteoleItem.editable" mat-icon-button (click)="this.editClick.emit(meteoleItem.meteole)">
        <fa-icon icon="edit"></fa-icon>
      </button>
      {{ meteoleItem.meteole.snapshotEolienFirstname }} {{ meteoleItem.meteole.snapshotEolienLastname }}
    </td>
  </ng-container>

  <ng-container matColumnDef="meeting">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-meeting-header">Date</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <button *ngIf="meteoleItem.editable" mat-icon-button (click)="this.editClick.emit(meteoleItem.meteole)">
        <fa-icon icon="edit"></fa-icon>
      </button>
      {{ meteoleItem.meteole.meeting?.year }} {{ meteoleItem.meteole.meeting?.month | monthToDate | date:'MMMM' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="satisfactionMission">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Mission" i18n="@@meteole-table-mission-header"
        i18n-matTooltip="@@meteole-satisfaction-mission">Mis.</span>
    </th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <mat-checkbox [checked]="meteoleItem.meteole.satisfactionMission" [disabled]="true" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionAccompanying">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Accompagnement RH" i18n="@@meteole-table-accompanying-header"
        i18n-matTooltip="@@meteole-satisfaction-accompanying">Acc.</span>
    </th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <mat-checkbox [checked]="meteoleItem.meteole.satisfactionAccompanying" [disabled]="true" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionAcknowledgement">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Reconnaissance" i18n="@@meteole-table-acknowledgement-header"
        i18n-matTooltip="@@meteole-satisfaction-acknowledgement">Rec.</span>
    </th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <mat-checkbox [checked]="meteoleItem.meteole.satisfactionAcknowledgement" [disabled]="true" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionBelonging">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Appartenance" i18n="@@meteole-table-belonging-header"
        i18n-matTooltip="@@meteole-satisfaction-belonging">App.</span>
    </th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <mat-checkbox [checked]="meteoleItem.meteole.satisfactionBelonging" [disabled]="true" color="primary">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionCareer">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Carrière" i18n="@@meteole-table-career-header"
        i18n-matTooltip="@@meteole-satisfaction-career">Car.</span>
    </th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <mat-checkbox [checked]="meteoleItem.meteole.satisfactionCareer" [disabled]="true" color="primary"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="summary">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-summary-header">Synthèse</th>
    <td mat-cell *matCellDef="let meteoleItem" class="shrink">
      <div fxLayout="row" fxLayoutAlign="center center">
        <ng-container [ngSwitch]="meteoleItem.meteole.summary">
          <fa-icon size="2x" icon="angry" *ngSwitchCase="0" class="black"></fa-icon>
          <fa-icon size="2x" icon="frown" *ngSwitchCase="1" class="red"></fa-icon>
          <fa-icon size="2x" icon="meh" *ngSwitchCase="2" class="orange"></fa-icon>
          <fa-icon size="2x" icon="laugh" *ngSwitchCase="3" class="green"></fa-icon>
          <fa-icon size="2x" icon="question" *ngSwitchDefault class="gray"></fa-icon>
        </ng-container>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="remarks">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-remarks-header" class="no-wrap">Remarques</th>
    <td mat-cell *matCellDef="let meteoleItem" [innerHTML]="meteoleItem.meteole.remarks | br | htmlSafe" valign="top">
    </td>
  </ng-container>
  <ng-container matColumnDef="actionsGodfather">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-actions-godfather-header" class="no-wrap">Action parrain
    </th>
    <td mat-cell *matCellDef="let meteoleItem" [innerHTML]="meteoleItem.meteole.actionsGodfather | br | htmlSafe"
      valign="top"></td>
  </ng-container>
  <ng-container matColumnDef="actionsMeeting">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-actions-meeting-header" class="no-wrap">Décision séance
    </th>
    <td mat-cell *matCellDef="let meteoleItem" [innerHTML]="meteoleItem.meteole.actionsMeeting | br | htmlSafe"
      valign="top"></td>
  </ng-container>

  <ng-container matColumnDef="empty-title">
    <th mat-header-cell *matHeaderCellDef></th>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
  </ng-container>
  <ng-container matColumnDef="empty-title2">
    <th mat-header-cell *matHeaderCellDef></th>
  </ng-container>
  <ng-container matColumnDef="eolien-title">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-eolien-title">Informations eolien</th>
  </ng-container>
  <ng-container matColumnDef="meeting-title">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-meeting-title">Informations</th>
  </ng-container>
  <ng-container matColumnDef="criteria-title">
    <th mat-header-cell *matHeaderCellDef colspan="5" i18n="@@meteole-criteria">Evaluation des critères
      de satisfaction</th>
  </ng-container>
  <ng-container matColumnDef="actions-title">
    <th mat-header-cell *matHeaderCellDef colspan="4" i18n="@@meteole-actions">Bilan et plan d'action
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headers"></tr>
  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20, 30]" [pageSize]="20" showFirstLastButtons></mat-paginator>