import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter } from 'src/interfaces/filter';
import { IFilteredEolien } from 'src/interfaces/filteredGodfather';

import { IMeteoleMeeting } from 'src/interfaces/http/meteole';

export const defaultHeaders = ['criteria-title', 'actions-title'];
export const defaultColumns = [
  'satisfactionMission',
  'satisfactionAccompanying',
  'satisfactionAcknowledgement',
  'satisfactionBelonging',
  'satisfactionCareer',
  'summary',
  'remarks',
  'actionsGodfather',
  'actionsMeeting'
];

@Component({
  selector: 'eole-meteole-table',
  templateUrl: './meteole-table.component.html',
})
export class MeteoleTableComponent implements OnInit {
  @Input()
  public headers = ['eolien-title', ...defaultHeaders];

  @Input()
  public columns = ['godchild', ...defaultColumns];

  /**
   * Meteole items
   */
  @Input()
  public items: MatTableDataSource<IMeteoleMeeting>;

  /**
   * Table type (meteoles | perimeter | collabs | agency| eolien)
   */
  @Input()
  public tableType: string;

  /**
   * Event triggered upon clicking the meteole edit button
   */
  @Output()
  public editClick = new EventEmitter();

  /**
   * Event triggered when changing a page in the paginator
   */
  @Output()
  public pageChange = new EventEmitter();

  /**
   * Total number of items to show pagination for
   */
  @Input()
  public totalItemCount: string;

  @ViewChild(MatPaginator, { static: true })
  public paginator: MatPaginator;

  /**
   * Show data filters when true
   */
  @Input()
  public useFilters = false;

  /**
   * Filters
   */
  public filter: IFilter;

  public godfathers: IFilteredEolien[] = [];
  public domains: string[] = [];
  public eoliens: IFilteredEolien[] = [];

  constructor() {
  }

  public ngOnInit(): void {
    this.paginator.page.subscribe(() => this.pageChange.emit({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
    }));
    this.items.paginator = this.paginator;

    if (this.showEolienInfo()) {
      this.columns = ['godchild', ...defaultColumns];
      this.headers = ['eolien-title', ...defaultHeaders];
    } else {
      this.columns = ['c-empty-title', 'year', 'month', ...defaultColumns];
      this.headers = ['empty-title', 'date', 'empty-title2', ...defaultHeaders];
    }

    if (this.useFilters) {
      const godfathers = this.items.data.map(e => {
        return {
          code: e.meteole.snapshotGodfatherCode,
          firstname: e.meteole.snapshotGodfatherFirstname,
          lastname: e.meteole.snapshotGodfatherLastname,
        };
      });
      this.godfathers = godfathers.filter((gf, index, self) =>
        index === self.findIndex((t) => t.code === gf.code)
      );

      this.domains = [...new Set(this.items.data.map(e => e.meteole.snapshotEolienDomain))].filter(domain => domain);

      this.eoliens = this.items.data.map(e => {
        return {
          id: e.meteole.eolienId,
          firstname: e.meteole.snapshotEolienFirstname,
          lastname: e.meteole.snapshotEolienLastname,
        };
      });

      this.filter = {};

      this.items.filterPredicate = this.filterPredicate.bind(this);
    }

    if (this.showGodfatherCode()) {
      this.columns = ['godfather', ...this.columns];
      this.headers = ['empty-title', ...this.headers];
    }
  }

  private filterPredicate(data: IMeteoleMeeting, filter: string) {
    const f = JSON.parse(filter) as IFilter;

    if (f.godfatherCode && data.meteole.snapshotGodfatherCode.toLowerCase() !== f.godfatherCode.toLowerCase()) {
      return false;
    }

    if (f.eolien && data.meteole.eolienId !== f.eolien) {
      return false;
    }

    if (f.domain && data.meteole.snapshotEolienDomain !== f.domain) {
      return false;
    }

    return true;
  }

  public showGodfatherCode(): boolean {
    return ['perimeter', 'collabs', 'agency', 'eolien'].includes(this.tableType);
  }

  public showYearMonths(): boolean {
    return ['eolien'].includes(this.tableType);
  }

  public showEolienInfo() {
    return ['meteoles', 'perimeter', 'collabs', 'agency'].includes(this.tableType);
  }

  public applyFilter() {
    this.items.filter = JSON.stringify(this.filter);
  }
}
