<mat-sidenav-container fxFill>
  <mat-sidenav #drawer class="sidenav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
    [disableClose]="(isHandset$ | async) === false">
    <!-- Side content Here -->
    <ng-content select="[eoleSide]"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" fxLayoutAlign="stretch stretch">
    <div fxFlex="grow" class="container">
      <!-- Content Here -->
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
