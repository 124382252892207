import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IUser } from 'src/interfaces/user';
import { ConfigService } from './core/config.service';

@Injectable({
  providedIn: 'root'
})
export class ImpersonateService {

  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  /**
   * Close a meeting
   */
  public impersonate(email: string): Observable<{ token: string, user: IUser }> {
    return this.http.post<{ token: string, user: IUser }>(`${this.configService.config.meteole.url}/auth/impersonate`, { email });
  }

  /**
   * Gets the closest meeting date
   */
  public filterImpersonateTarget(predicate: string): Observable<{ total: number; results: string[] }> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<{ total: number; results: string[] }>(`${this.configService.config.meteole.url}/auth/impersonate/users`, { params: { predicate } });
  }
}
