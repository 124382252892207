import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * Component that can unsubscribe subscriptions when destroyed
 */
export class SubscribableComponent implements OnDestroy {
  public subs: Subscription[] = [];

  public ngOnDestroy(): void {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }
}
