import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { MeetingService } from 'src/app/services/meeting.service';
import { IMeeting } from 'src/interfaces/http/meeting';

@Component({
  selector: 'eole-dialog-participate',
  templateUrl: './dialog-participate.component.html',
})
export class DialogParticipateComponent implements OnInit, OnDestroy {
  /**
   * Loading state
   */
  public loading = false;
  /**
   * Saving subscription
   */
  public sending: Subscription;

  /**
   * Form
   */
  public participateForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DialogParticipateComponent>,
    private fb: FormBuilder,
    private meetingService: MeetingService,
    @Inject(MAT_DIALOG_DATA) public data: IMeeting,
  ) { }

  public ngOnInit() {
    // Generate default form
    this.participateForm = this.fb.group({
      attending: [true, [Validators.required]],
    });
  }

  public ngOnDestroy() {
    // Cancel any pending save
    if (this.sending) {
      this.sending.unsubscribe();
    }
  }

  /**
   * Close the modal
   * @param attending If saved, the attending value
   */
  public closeDialog(attending?: boolean) {
    this.dialogRef.close(attending);
  }

  /**
   * Save the participation.
   * If already saving, will cancel the pending request
   */
  public send(attending: boolean) {
    // Cancel if already sending
    if (this.loading && this.sending) {
      this.sending.unsubscribe();
      return;
    }

    // Save it
    this.loading = true;
    this.sending = this.meetingService.updateParticipation(this.data.year, this.data.month, attending)
      .pipe(finalize(() => { this.loading = false; }))
      .subscribe(() => this.closeDialog(attending));
  }
}
