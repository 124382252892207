<eole-sidenav>

    <eole-meteole-side eoleSide>
        <mat-progress-bar *ngIf="sideLoading" mode="indeterminate"></mat-progress-bar>
        <p *ngIf="sideLoading" i18n="@@side-loading-meteole">Chargement des filleuls</p>
        <p *ngIf="!sideLoading && !godchildren.length" i18n="@@side-no-meteole">Pas de filleuls</p>
        <button *ngFor="let godchild of godchildren" routerLinkActive="mat-primary"  fxLayoutAlign="start stretch"
            mat-flat-button [routerLink]="['/meteoles/eoliens',  godchild.id]">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div>
                    {{godchild.fname}} {{godchild.lname}}
                </div>
            </div>
        </button>
    </eole-meteole-side>

    <div fxLayout="column" fxLayoutAlign="center" *ngIf="eolien && godchildren.length > 0">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <h1>{{getEolienObject().fname}} {{getEolienObject().lname}}</h1>
        </div>
        <p *ngIf="loading" i18n="@@side-loading-meteole">Chargement des météoles</p>

        <eole-meteole-table *ngIf="!loading" [tableType]="'eolien'" [items]="meteoleItems" (editClick)="onMeteoleEditClicked($event)"></eole-meteole-table>

    </div>
</eole-sidenav>