import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthToDate'
})
export class MonthToDatePipe implements PipeTransform {
  /**
   * Transform a month number to a date
   * @param value Month number
   */
  public transform(value?: number): Date | undefined {
    if (typeof value !== 'number' || value > 12 || value < 1) {
      return;
    }
    const date = moment().local();
    date.month(value - 1);
    return date.toDate();
  }

}
