<table mat-table fxFill #table [dataSource]="dataSource"  [trackBy]="track" class="large-table">
  <ng-container matColumnDef="covered">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Couvert en séance" i18n="@@meteole-table-covered-header"
        i18n-matTooltip="@@meteole-table-covered-full-tooltip">Couv.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.covered" color="primary" matTooltip="Couvert"
        i18n-matTooltip="@@meteole-table-covered-tooltip"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="godfather">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-godfather-header">Parrain</th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <div fxLayout="columns" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-icon-button [matMenuTriggerFor]="menuParticipation">
            <ng-container [ngSwitch]="meteole.godfatherParticipation?.attending">
              <fa-icon icon="check" *ngSwitchCase="true" matTooltip="Présent" i18n-matTooltip="@@meteole-table-participation-true" class="green"></fa-icon>
              <fa-icon icon="times" *ngSwitchCase="false" matTooltip="Absent" i18n-matTooltip="@@meteole-table-participation-false" class="red"></fa-icon>
              <fa-icon icon="question" *ngSwitchDefault matTooltip="Non répondu" i18n-matTooltip="@@meteole-table-participation-other" class="gray"></fa-icon>
            </ng-container>
          </button>
          <mat-menu #menuParticipation="matMenu">
            <button mat-menu-item role="menuitemradio"
              (click)="meteole.godfatherParticipation.attending = true">
              <div fxLayout="columns" fxLayoutAlign="start center" fxLayoutGap="10px">
                <fa-icon icon="check" class="green"></fa-icon>
                <span i18n="@@meteole-table-participation-true">Présent</span>
              </div>
            </button>
            <button mat-menu-item role="menuitemradio"
              (click)="meteole.godfatherParticipation.attending = false">
              <div fxLayout="columns" fxLayoutAlign="start center" fxLayoutGap="10px">
                <fa-icon icon="times" class="red"></fa-icon>
                <span i18n="@@meteole-table-participation-false">Absent</span>
              </div>
            </button>
          </mat-menu>
        </div>
        <div *ngIf="meteole.snapshotGodfatherCode"
          [matTooltip]="meteole.snapshotGodfatherFirstname+' '+meteole.snapshotGodfatherLastname">
          {{ meteole.snapshotGodfatherCode }}
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="godchild">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-eolien-header">Collaborateur</th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      {{ meteole.snapshotEolienFirstname }} {{ meteole.snapshotEolienLastname }}
    </td>
  </ng-container>

  <ng-container matColumnDef="satisfactionMission">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Mission" i18n="@@meteole-table-mission-header"
        i18n-matTooltip="@@meteole-satisfaction-mission">Mis.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.satisfactionMission" color="primary"></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionAccompanying">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Accompagnement RH" i18n="@@meteole-table-accompanying-header"
        i18n-matTooltip="@@meteole-satisfaction-accompanying">Acc.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.satisfactionAccompanying" color="primary"></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionAcknowledgement">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Reconnaissance" i18n="@@meteole-table-acknowledgement-header"
        i18n-matTooltip="@@meteole-satisfaction-acknowledgement">Rec.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.satisfactionAcknowledgement" color="primary"></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionBelonging">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Appartenance" i18n="@@meteole-table-belonging-header"
        i18n-matTooltip="@@meteole-satisfaction-belonging">App.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.satisfactionBelonging" color="primary"></mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="satisfactionCareer">
    <th mat-header-cell *matHeaderCellDef>
      <span matTooltipPosition="above" matTooltip="Carrière" i18n="@@meteole-table-career-header"
        i18n-matTooltip="@@meteole-satisfaction-career">Car.</span>
    </th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <mat-checkbox [(ngModel)]="meteole.satisfactionCareer" color="primary"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="summary">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-summary-header">Synthèse</th>
    <td mat-cell *matCellDef="let meteole" class="shrink">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="-5px">
        <ng-container *ngFor="let summary of meteole.previousMeteoles">
          <button mat-icon-button [disabled]="true" [ngSwitch]="summary">
            <fa-icon size="2x" icon="angry" *ngSwitchCase="0" class="black"></fa-icon>
            <fa-icon size="2x" icon="frown" *ngSwitchCase="1" class="red"></fa-icon>
            <fa-icon size="2x" icon="meh" *ngSwitchCase="2" class="orange"></fa-icon>
            <fa-icon size="2x" icon="laugh" *ngSwitchCase="3" class="green"></fa-icon>
            <fa-icon size="2x" icon="question" *ngSwitchDefault class="gray"></fa-icon>
          </button>
        </ng-container>
        <button mat-icon-button [matMenuTriggerFor]="menuSummary">
          <ng-container [ngSwitch]="meteole.summary">
            <fa-icon size="2x" icon="angry" *ngSwitchCase="0" class="black"></fa-icon>
            <fa-icon size="2x" icon="frown" *ngSwitchCase="1" class="red"></fa-icon>
            <fa-icon size="2x" icon="meh" *ngSwitchCase="2" class="orange"></fa-icon>
            <fa-icon size="2x" icon="laugh" *ngSwitchCase="3" class="green"></fa-icon>
            <fa-icon size="2x" icon="question" *ngSwitchDefault class="gray"></fa-icon>
          </ng-container>
        </button>
        <mat-menu #menuSummary="matMenu">
          <button [ngClass]="{'primary-bg': meteole.summary===0}" mat-menu-item role="menuitemradio"
            (click)="meteole.summary = 0">
            <fa-icon size="2x" icon="angry" class="black"></fa-icon>
          </button>
          <button [ngClass]="{'primary-bg': meteole.summary===1}" mat-menu-item role="menuitemradio"
            (click)="meteole.summary = 1">
            <fa-icon size="2x" icon="frown" class="red"></fa-icon>
          </button>
          <button [ngClass]="{'primary-bg': meteole.summary===2}" mat-menu-item role="menuitemradio"
            (click)="meteole.summary = 2">
            <fa-icon size="2x" icon="meh" class="orange"></fa-icon>
          </button>
          <button [ngClass]="{'primary-bg': meteole.summary===3}" mat-menu-item role="menuitemradio"
            (click)="meteole.summary = 3">
            <fa-icon size="2x" icon="laugh" class="green"></fa-icon>
          </button>
          <button [ngClass]="{'primary-bg': meteole.summary===null}" mat-menu-item role="menuitemradio"
            (click)="meteole.summary = null">
            <fa-icon size="2x" icon="question" class="gray">
            </fa-icon>
          </button>
        </mat-menu>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="remarks">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-remarks-header">Remarques</th>
    <td mat-cell *matCellDef="let meteole" valign="top">
      <mat-form-field>
        <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize (blur)="sanitize(meteole, 'remarks')" type="text" [(ngModel)]="meteole.remarks"
          autocomplete="off"></textarea>
        <mat-hint>
          {{ meteole.remarks?.length || 0 }} / {{maxLength}}
        </mat-hint>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="actionsGodfather">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-actions-godfather-header" class="no-wrap">Action parrain</th>
    <td mat-cell *matCellDef="let meteole" valign="top">
      <mat-form-field>
        <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize (blur)="sanitize(meteole, 'actionsGodfather')" type="text" [(ngModel)]="meteole.actionsGodfather"
          autocomplete="off"></textarea>
        <mat-hint>
          {{ meteole.actionsGodfather?.length || 0 }} / {{maxLength}}
        </mat-hint>
      </mat-form-field>
    </td>
  </ng-container>
  <ng-container matColumnDef="actionsMeeting">
    <th mat-header-cell *matHeaderCellDef i18n="@@meteole-table-actions-meeting-header" class="no-wrap">Décision séance</th>
    <td mat-cell *matCellDef="let meteole" valign="top">
      <mat-form-field>
        <textarea matInput [maxlength]="maxLength" cdkTextareaAutosize (blur)="sanitize(meteole, 'actionsMeeting')" type="text" [(ngModel)]="meteole.actionsMeeting"
          autocomplete="off"></textarea>
        <mat-hint>
          {{ meteole.actionsMeeting?.length || 0 }} / {{maxLength}}
        </mat-hint>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="eolien-title">
    <th mat-header-cell *matHeaderCellDef colspan="3" i18n="@@meteole-table-eolien-title">Informations collaborateur</th>
  </ng-container>
  <ng-container matColumnDef="criteria-title">
    <th mat-header-cell *matHeaderCellDef colspan="5" i18n="@@meteole-criteria">Evaluation des critères
      de satisfaction</th>
  </ng-container>
  <ng-container matColumnDef="actions-title">
    <th mat-header-cell *matHeaderCellDef colspan="4" i18n="@@meteole-actions">Bilan et plan d'action
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headers"></tr>
  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
