import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { SubscribableComponent } from 'src/app/services/core/subscribeable.component';
import { ImpersonateService } from 'src/app/services/impersonate.service';

@Component({
  selector: 'eole-dialog-impersonate',
  templateUrl: './dialog-impersonate.component.html',
})
export class DialogImpersonateComponent extends SubscribableComponent implements OnInit {
  public targets: Observable<{ total: number; results: string[] }>;
  public impersonateForm: FormGroup;

  /**
   * Mobile/Desktop observable
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    public oidcSecurityService: OidcSecurityService,
    public dialogRef: MatDialogRef<DialogImpersonateComponent>,
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private imperonateService: ImpersonateService,
    private auth: AuthenticationService,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit() {
    this.impersonateForm = this.fb.group({
      mail: null
    });

    this.targets = this.impersonateForm.get('mail').valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.imperonateService.filterImpersonateTarget(value))
      );
  }

  public async send() {
    this.subs.push(
      this.imperonateService.impersonate(this.impersonateForm.get('mail').value).subscribe((result) => {
        console.log(result.token, result.user);
        this.auth.authenticate(result.token, result.user);

        // TODO: improve impersonate ? Get back to original user when refreshing
        this.router.navigateByUrl('/');
        this.dialogRef.close(true);
      })
    );
  }
}
