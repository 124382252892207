import { NgModule } from '@angular/core';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  faAngry,
  faArrowLeft,
  faBars,
  faChartBar,
  faCheck,
  faChevronDown,
  faChevronRight,
  faCircle,
  faCodeBranch,
  faCog,
  faEdit,
  faFileImport,
  faFrown,
  faLaugh,
  faMeh,
  faPlay,
  faPlus,
  faQuestion,
  faSave,
  faSignOutAlt,
  faTasks,
  faTimes,
  faUnlock,
  faUserFriends,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
} from '@fortawesome/free-regular-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class FaIconModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faAngry,
      faArrowLeft,
      faBars,
      faChartBar,
      faCheck,
      faChevronDown,
      faChevronRight,
      faCircle,
      faCodeBranch,
      faCog,
      faEdit,
      faFrown,
      faLaugh,
      faMeh,
      faPlay,
      faPlus,
      faQuestion,
      faSave,
      faSignOutAlt,
      faTasks,
      faTimes,
      faUnlock,
      faUserFriends,
      faUserSecret,
      faFileImport,
      faCalendarAlt,
    );
  }
}
