import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IMeteole, IMeteoleSession } from 'src/interfaces/http/meteole';
import { ConfigService } from './core/config.service';

@Injectable({
  providedIn: 'root'
})
export class MeteoleService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient
  ) { }

  public getUserMeteolesByDate(year: number, month: number): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles`);
  }

  public getUnderlingsMeteolesByDate(year: number, month: number): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/godchildren`);
  }

  public getCollabsMeteolesByDate(year: number, month: number): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/collabs`);
  }

  public getAgencyMeteolesByDate(year: number, month: number): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/agency`);
  }

  public getAllMeteolesByDate(year: number, month: number): Observable<IMeteoleSession[]> {
    return this.http.get<IMeteoleSession[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/all`);
  }

  public getGodfatherMeteolesByDate(year: number, month: number, godfatherCode: string): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/${godfatherCode}`);
  }

  public updateMeteole(eolienId: number, meetingId: number, meteole: IMeteole): Observable<{ success: boolean }> {
    // tslint:disable-next-line:max-line-length
    return this.http.put<{ success: boolean }>(`${this.configService.config.meteole.url}/meteoles/${eolienId}/meeting/${meetingId}`, meteole);
  }

  public getGodchildMeteolesByDate(year: number, month: number, godchildId: number): Observable<IMeteole> {
    return this.http.get<IMeteole>(`${this.configService.config.meteole.url}/meetings/${year}/${month}/meteoles/${godchildId}`);
  }

  public getGodchildMeteoles(godchildId: number): Observable<IMeteole[]> {
    return this.http.get<IMeteole[]>(`${this.configService.config.meteole.url}/meteoles/${godchildId}`);
  }

  /**
   * Save a batch of meteoles (admin only)
   * @param meteoles Meteoles to save
   */
  public updateMeteoles(meteoles: IMeteole[]): Observable<IMeteole[]> {
    return this.http.put<IMeteole[]>(`${this.configService.config.meteole.url}/meteoles`, meteoles);
  }
}
